/* You can add global styles to this file, and also import other style files */

$heading-font: 'Quicksand', sans-serif;
$common-font: 'Poppins', sans-serif;
$all-size: 15px;
$transition: .5s all ease;
$blue-color: #221668;
$grey-color: #5f5656;
//$pink-color: #fe4a55;
$pink-color: #6195FF; /// MM: style override. Used the same blue used on Fikisa site
$white-color: #fff;
$green-color: #21a212;

/*----- Default CSS -----*/
body {
    font-family: $common-font;
    color: $grey-color;
    background-color: $white-color;
    font-size: $all-size;
}
h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font;
    line-height: 1.4;
    font-weight: 700;
}
p {
    line-height: 1.8;
}
a {
    transition: $transition;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
img {
    max-width: 100%;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pb-100 {
    padding-bottom: 100px;
}
button {
    &:focus{
        outline: 0;
    }
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
/*----- End Default CSS -----*/

/*----- Home Demo One CSS -----*/
/*-- Navbar --*/
.main-nav {
    background: transparent;
    padding-top: 30px;
    transition: $transition;

    .container-fluid {
        padding-left: 50px;
        padding-right: 50px;
    }
    nav {
        padding-left: 0;
        padding-right: 0;

        .navbar-nav {
            margin-left: auto;
            margin-right: auto;

            .nav-item {
                position: relative;

                &:hover {
                    .dropdown-menu {
                        transform: rotateX(0deg);
                    }
                }
                &:hover {
                    a {
                        color: $pink-color;
                    }
                }
                a {
                    color: $blue-color;
                    text-transform: capitalize;

                    &:hover, &:focus, &.active {
                        color: $pink-color;
                    }
                }
                .dropdown-menu {
                    background: $blue-color;
                    padding: 0;
                    transform: rotateX(-45deg);

                    li {
                        &:hover {
                            .dropdown-menu {
                                top: 0 !important;
                            }
                            a {
                                color: $pink-color;
                            }
                        }
                        a {                                
                            position: relative;

                            &:before {
                                position: absolute;
                                content: '';
                                width: 0;
                                height: 100%;
                                left: 0;
                                top: 0;
                                background-color: $pink-color;
                                transition: $transition;
                                opacity: 0;
                                border-radius: 0 10px 10px 0;
                            }
                            &:hover, &:focus, &.active {
                                color: $pink-color;
                                padding-left: 10px;

                                &:before {
                                    width: 3px;
                                    opacity: 1;
                                }
                            }
                        }
                        .dropdown-menu {
                            left: unset;
                            right: -100%;

                            li {
                                a {
                                    &:hover, &:focus, &.active {
                                        color: $pink-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-toggle::after {
        display: none;
    }
    i {
        position: relative;
        top: 3px;
        display: inline-block;
        font-size: 18px;
    }
    .tooltip-span {
        display: inline-block;
        color: $white-color;
        background-color: $pink-color;
        font-size: 12px;
        position: absolute;
        top: -10px;
        right: 10px;
        padding: 3px 10px;
        border-radius: 4px;
        transition: $transition;
        visibility: visible;

        &:before {
            position: absolute;
            content: '';
            bottom: -3px;
            left: 0;
            border-right: 20px solid transparent;
            border-top: 7px solid $pink-color;
        }
        &.two {
            background-color: $blue-color;

            &:before {
                border-top: 7px solid $blue-color;
            }
        }
    }
    &.menu-shrink {
        background-color: $white-color;
        padding-top: 5px;
        padding-bottom: 5px;
        box-shadow: 0px 0px 20px 0px #ded8ff;

        .tooltip-span {
            opacity: 0;
            visibility: hidden;
        }
    }
}
.side-nav {
    a {
        display: inline-block;
    }
    .login-left {
        color: $blue-color;
        margin-right: 30px;

        i {
            display: inline-block;
            color: $blue-color;
            font-size: 18px;
            margin-right: 3px;
            position: relative;
            top: 1px;
        }
        &:hover {
            color: $pink-color;
        }
    }
    .job-right {
        background-color: $blue-color;
        color: $white-color;
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 65px;
        border-radius: 5px;
        line-height: 50px;

        i {
            display: inline-block;
            height: 50px;
            line-height: 50px;
            padding-left: 12px;
            padding-right: 12px;
            font-size: 20px;
            color: $white-color;
            background-color: $pink-color;
            position: absolute;
            top: 10px;
            right: 0;
            border-radius: 0 5px 5px 0;
        }
        &:hover {
            background-color: $pink-color;
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.banner-area {
    background-color: #f8e8e9;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 70px;
        height: 100%;
        top: 0;
        right: 0;
        background-color: $white-color;
    }
    .container-fluid {
        padding-left: 50px;
        padding-right: 0;

        .row {
            margin-left: 0;
            margin-right: 0;
            
            .col-lg-8 {
                padding-left: 0;
                padding-right: 0;
            }
            .col-lg-4 {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .banner-shape {            
        position: absolute;
        bottom: 0;
        left: 0;

        img {
            width: 100%;
            height: 100px;
        }
    }
}
.banner-content {
    height: 1070px;

    h1 {
        color: $blue-color;
        font-size: 70px;
        margin-bottom: 25px;
        max-width: 710px;

        span {
            color: $pink-color;
        }
    }
    p {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 20px;
        color: #555555;
    }
    .banner-form-area {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 205px;
        border-radius: 5px;
        background-color: $white-color;
        position: relative;
        max-width: 925px;
        margin-bottom: 40px;

        .col-lg-4 {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
        .form-group {
            margin-bottom: 0;
            position: relative;

            &.two {
                .form-control {
                    border-right: 0;
                }
            }
            label {
                margin-bottom: 0;
                position: absolute;
                left: 0;
                top: 13px;
                color: $grey-color;
                font-size: 20px;
            }
            .form-control {
                height: 50px;
                border: 0;
                font-size: 15px;
                padding-left: 30px;
                border-right: 1px solid #eeeeee !important;
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                }
            }
            ::placeholder {
                color: $grey-color;
            }
            .nice-select {
                height: 50px;
                border: 0;
                display: block;
                width: 100%;
                line-height: 50px;
                color: $grey-color;
                padding-left: 0;
                border-radius: 0;
                border-right: 1px solid #eeeeee !important;

                &:after {
                    right: 35px;
                }
                .list {
                    width: 100%;
                    display: block;
                }
            }
        }
        .btn {
            border: 0;
            transition: $transition;
            background-color: $blue-color;
            color: $white-color;
            height: 50px;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 20px;
            padding-right: 65px;
            border-radius: 5px;
            position: absolute;
            top: 10px;
            right: 15px;

            i {
                display: inline-block;
                height: 50px;
                line-height: 50px;
                padding-left: 12px;
                padding-right: 12px;
                font-size: 20px;
                color: $white-color;
                background-color: $pink-color;
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 5px 5px 0;
            }
            &:hover {
                background-color: $pink-color;
            }
        }
    }
    .banner-key {
        ul {
            margin: 0;
            padding: 0;
    
            li {
                list-style-type: none;
                display: inline-block;
    
                span {
                    display: block;
                    color: $blue-color;
                    background-color: #e2d3dc;
                    padding: 8px 12px;
                    border-radius: 5px;
                    margin-right: 18px;
                }
                a {
                    display: block;
                    color: $pink-color;
                    margin-right: 5px;
    
                    &:hover {
                        color: $blue-color;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.banner-img {
    position: relative;

    img {
        position: absolute;

        &:nth-child(1) {
            top: 140px;
            right: 0;
            border-radius: 50px 0 0 50px;
            max-width: 235px;
        }
        &:nth-child(2) {
            top: 175px;
            right: 245px;
        }
        &:nth-child(3) {
            top: 395px;
            right: 175px;
            max-width: 120px;
        }
        &:nth-child(4){
            top: 360px;
            right: 305px;
            animation: banner-ani-one 3s infinite linear;
        }
        &:nth-child(5) {
            top: 370px;
            right: 390px;
            animation: banner-ani-two 3s infinite linear;
        }
        &:nth-child(6) {
            top: 300px;
            right: 515px;
            animation: banner-ani-four 3s infinite linear;
        }
        &:nth-child(7) {
            top: 430px;
            right: 0;
            max-width: 100px;
        }
        &:nth-child(8) {
            top: 475px;
            right: 115px;
            animation: banner-ani-three 3s infinite linear;
        }
        &:nth-child(9) {
            top: 485px;
            right: 195px;
            max-width: 90px;
        }
        &:nth-child(10) {
            top: 745px;
            right: 10px;
            max-width: 330px;
            animation: border-transform-default 10s linear infinite alternate forwards;
        }
        &:nth-child(11) {
            top: 625px;
            right: 0;
            animation: banner-ani-two 3s infinite linear;
        }
        &:nth-child(12) {
            top: 625px;
            right: 85px;
            max-width: 160px;
        }
        &:nth-child(13) {
            top: 565px;
            right: 272px;
            max-width: 150px;
        }
    }
    .inner {
        position: absolute;
        top: 55px;
        right: 380px;

        p {
            display: inline-block;
            color: #fff;
            background-color: #221668;
            max-width: 300px;
            position: absolute;
            left: -410px;
            top: 85px;    
            padding: 25px 25px 25px;
            margin-bottom: 0;
            border-radius: 30px 30px 0 0;
    
            &:before {
                position: absolute;
                content: '';
                bottom: -10px;
                right: 0;
                border-left: 25px solid transparent;
                border-top: 14px solid #221668;
            }
        }
    }
}
@keyframes banner-ani-one {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.10;
    }
    100% {
        opacity: 1;
    }
}
@keyframes banner-ani-two {
    0% {
        opacity: 0.10;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.10;
    }
}
@keyframes banner-ani-three {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes banner-ani-four {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(-180deg);
    }
    100% {
        transform: rotateY(-360deg);
    }
}
@keyframes border-transform-default {
    0%, to {
        border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    }
}
/*-- End Banner --*/

/*-- Work --*/
.section-title {
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
    margin-top: 2px;

    .sub-title {
        display: inline-block;
        color: $blue-color;
        position: relative;
        padding-left: 10px;
        z-index: 1;

        &:before {
            position: absolute;
            content: '';
            width: 20px;
            height: 25px;
            left: 0;
            top: -2px;
            background-color: $pink-color;
            border-radius: 50%;
            z-index: -1;
        }
    }
    h2 {
        font-size: 40px;
        color: $blue-color;
        margin-bottom: 0;
    }
}
.work-item {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    background-color: #f8e8e9;
    padding: 30px 30px 28px;
    border-radius: 5px;
    transition: $transition;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 0;
        left: 0;
        bottom: 0;
        background-color: $blue-color;
        opacity: 0;
        z-index: -1;
        border-radius: 5px;
        transition: $transition;
    }
    &:hover {
        transform: translate(0px, -10px);

        &:before {
            opacity: 1;
            height: 100%;
        }
        i {
            transform: rotateY(360deg);
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }
    i {
        display: inline-block;
        color: $pink-color;
        font-size: 45px;
        position: absolute;
        top: 30px;
        right: 30px;
        line-height: 45px;
        transition: $transition;
    }
    span {
        display: block;
        color: $pink-color;
        margin-bottom: 10px;
    }
    h3 {
        font-size: 20px;
        color: $blue-color;
        margin-bottom: 10px;
        transition: $transition;
    }
    p {
        margin-bottom: 0;
        transition: $transition;
    }
}
/*-- End Work --*/

/*-- Jobs --*/
#container .mix {
    display: none;
}
.sorting-menu {
    text-align: right;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            color: #7068a3;
            font-size: 17px;
            transition: $transition;
            cursor: pointer;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            &:hover, &.active {
                color: $pink-color;
            }
        }
    }
}
.job-browse {
    text-align: center;

    p {
        margin-bottom: 0;
        font-size: 17px;
        color: #7068a3;

        a {
            display: inline-block;
            color: $pink-color;
            font-weight: 500;
            border-bottom: 1px solid $pink-color;

            &:hover {
                color: $blue-color;
                border-bottom: 1px solid $blue-color;
            }
        }
    }
}
.job-item {
    padding: 25px 35px 30px;
    border: 1px solid #eeeeee;
    position: relative;
    transition: $transition;
    margin-bottom: 30px;
    background-color: $white-color;

    &:hover {
        border-color: $pink-color;
        background-color: #f8e8e9;
    }
    .job-left {
        position: relative;
        padding-left: 100px;

        img {
            position: absolute;
            top: 8px;
            left: 0;
        }
        h3 {
            font-size: 20px;
            margin-bottom: 3px;
            color: $blue-color;

        }
        p {
            margin-bottom: 12px;
            color: $grey-color;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                color: $pink-color;
                margin-right: 7px;

                i {
                    display: inline-block;
                    font-size: 16px;
                    margin-right: 6px;
                }
                &:nth-child(3) {
                    color: #7068a3;
                }
                &:nth-child(4) {
                    color: #7068a3;

                    i {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        margin-right: 1px;
                    }
                }
            }
        }
    }
    .job-right {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                color: #7068a3;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    font-size: 18px;
                    margin-right: 3px;
                    position: relative;
                    top: 3px;
                }
                span {
                    display: inline-block;
                    color: $blue-color;
                    background-color: #ded8ff;
                    padding: 3px 8px;
                    margin-top: 4px;
                }
            }
        }
    }
}
.feature-top-right {
    position: absolute;
    top: -1px;
    right: -1px;

    span {
        display: inline-block;
        color: $white-color;
        background-color: $pink-color;
        padding: 4px 12px;
        font-size: 12px;
    }
}
/*-- End Jobs --*/

/*-- Explore --*/
.explore-area {
    background-image: url('assets/img/home-one/explore-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000000;
        opacity: .45;
    }
    .explore-item {
        position: relative;
        max-width: 590px;
        margin-left: auto;

        .section-title {
            margin-bottom: 25px;

            .sub-title {
                color: $white-color;
            }
            h2 {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
            margin-bottom: 30px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                .left-btn {
                    background-color: $white-color;
                    color: $blue-color;
                    height: 50px;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 20px;
                    padding-right: 65px;
                    border-radius: 5px;
                    line-height: 50px;
                    display: inline-block;
                    position: relative;
                    margin-right: 20px;
            
                    i {
                        display: inline-block;
                        height: 50px;
                        line-height: 50px;
                        padding-left: 12px;
                        padding-right: 12px;
                        font-size: 20px;
                        color: $white-color;
                        background-color: $pink-color;
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-radius: 0 5px 5px 0;
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $pink-color;
                    }
                }
                span {
                    display: inline-block;
                    color: $white-color;
                    margin-right: 20px;
                }
                .right-btn {
                    color: $white-color;
                    border: 1px solid $white-color;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 25px;
                    display: inline-block;
                    position: relative;
                    top: 4px;

                    i {
                        line-height: 40px;
                    }
                    &:hover {
                        color: $blue-color;
                        background-color: $white-color;
                    }
                }
            }
        }
    }
}
/*-- End Explore --*/

/*-- Counter --*/
.counter-area {
    background-color: #f8e8e9;
    position: relative;
    padding-bottom: 170px;

    &:before {
        position: absolute;
        content: '';
        width: 70px;
        height: 100%;
        top: 0;
        right: 0;
        background-color: $white-color;
    }
    .counter-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: rotateY(180deg);

        img {
            height: 100px;
            width: 100%;
        }
    }
}
.counter-item {
    margin-bottom: 30px;
    position: relative;
    padding-left: 95px;

    i {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 70px;
        height: 70px;
        line-height: 70px;
        color: $pink-color;
        background-color: $white-color;
        text-align: center;
        font-size: 35px;
        border-radius: 5px;
    }
    p {
        margin-bottom: 2px;
        color: $pink-color;
    }
    h3 {
        font-size: 30px;
        margin-bottom: 0;
        color: $blue-color;
    }
}
/*-- End Counter --*/

/*-- Company --*/
.cmn-link {
    text-align: right;

    a {
        display: inline-block;
        color: $pink-color;
        font-size: 17px;
        position: relative;

        i {
            display: inline-block;
            font-size: 30px;
            color: $pink-color;
            transition: $transition;
            line-height: 30px;
        }
        .one {
            position: absolute;
            top: -2px;
            left: -42px;
            opacity: 1;
        }
        .two {      
            position: absolute;
            top: -2px;
            right: -5px;
            left: unset;
            opacity: 0;
        }
        &:hover {
            padding-right: 42px;

            .one {
                opacity: 0;
            }
            .two {
                opacity: 1;
                right: 0;
            }
        }
    }
}
.company-item {
    margin-bottom: 30px;
    border: 1px dashed #636363;
    padding: 25px 20px 25px;
    transition: $transition;
    position: relative;

    &:hover {
        transform: translate(0, -10px);

        .top {
            a {
                img {
                    transform: rotateY(360deg);
                }
            }
        }
        .bottom {
            a {
                right: 10px;
                color: $pink-color;
            }
        }
    }
    .top {
        margin-bottom: 18px;

        a {
            display: block;

            img {
                margin-bottom: 25px;
                transition: $transition;
                height: 65px;
            }
        }
        h3 {
            font-size: 20px;
            margin-bottom: 8px;
            display: inline-block;

            a {
                color: $blue-color;
                display: inline-block;

                &:hover {
                    color: $pink-color;
                }
            }
        }
        span {
            display: inline-block;
            color: $pink-color;
            background-color: #ffe4e6;
            border-radius: 5px;
            padding: 4px 10px;
            font-size: 12px;
            margin-left: 3px;
            position: relative;
            top: -1px;
        }
        p {
            color: #615798;
            margin-bottom: 0;
            font-size: 13px;

            i {
                display: inline-block;
                font-size: 14px;
                margin-right: 3px;
            }
        }
    }
    .bottom {
        position: relative;

        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 30px;

            li {
                list-style-type: none;
                display: inline-block;
                color: #615798;
                font-size: 12px;
                background-color: #ececec;
                border-radius: 5px;
                padding: 6px 12px;
                margin-bottom: 10px;
                margin-right: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        span {
            display: block;
            color: #857cb5;
            font-size: 12px;
            margin-bottom: 5px;
        }
        h4 {
            margin-bottom: 0;
            font-size: 20px;
            color: $pink-color;
        }
        a {
            display: inline-block;
            color: #857cb5;
            font-size: 30px;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}
/*-- End Company --*/

/*-- Location --*/
.location-area {
    .owl-prev {
        position: absolute;
        top: 40%;
        left: 35px;
        color: $white-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $pink-color !important;
            background-color: transparent !important;

            &:after {
                background-color: #fff;
            }
        }
        &:after {
            position: absolute;
            top: 12px;
            left: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ef4605;
            z-index: -1;
            transition: $transition;
        }
    }
    .owl-next {
        position: absolute;
        top: 40%;
        right: 35px;
        color: $white-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $pink-color !important;
            background-color: transparent !important;

            &:after {
                background-color: #fff;
            }
        }
        &:after {
            position: absolute;
            top: 12px;
            right: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ef4605;
            z-index: -1;
            transition: $transition;
        }
    }
    .location-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: 45px;
            }
            .owl-next {
                opacity: 1;
                right: 45px;
            }
        }
    }
}
.location-item {
    background-color: #f9f9f9;
    border-radius: 5px;
    border: 1px dashed #cdcdcd;
    padding: 15px;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;

    &:hover {
        .top {
            a {
                transform: scale(1.1);
            }
        }
    }
    .top {
        overflow: hidden;
        border-radius: 5px;
        position: relative;
        z-index: 2;

        a {
            display: block;
            position: relative;    

            &:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                bottom: 0;
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
                opacity: .60;
            }
    
            img {
                width: 100%;
                transition: $transition;
            }
        }
    }
    span {
        display: inline-block;
        position: absolute;
        top: 30px;
        right: 30px;
        color: $white-color;
        background-color: $pink-color;
        border-radius: 5px;
        padding: 4px 10px;
        z-index: 2;
    }
    h3 {
        font-size: 20px;
        position: absolute;
        left: 30px;
        bottom: 25px;
        margin-bottom: 0;
        z-index: 2;

        a {
            color: $white-color;
            display: block;

            &:hover {
                color: $pink-color;
            }
        }
    }
}
/*-- End Location --*/

/*-- Feedback --*/
.feedback-area {
    background-color: #f8e8e9;
    position: relative;
    padding-bottom: 150px;

    &:before {
        position: absolute;
        content: '';
        width: 70px;
        height: 100%;
        top: 0;
        right: 0;
        background-color: $white-color;
    }
    .feedback-shape {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        img {
            width: 100%;
            height: 100px;
        }
    }
    .section-title {
        text-align: center;

        .sub-title {
            display: inline-block;
        }
    }
    .feedback-item {
        position: relative;
        padding: 40px 65px 40px 30px;
        border-radius: 5px;
        background-color: $white-color;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 30px;
        box-shadow: 0px 0px 20px 0px #dddddd24;

        &:hover {
            img {
                right: 5px;
                bottom: 5px;
            }
        }
        h3 {
            font-size: 20px;
            color: $blue-color;
            margin-bottom: 5px;
        }
        span {
            display: block;
            color: $pink-color;
            margin-bottom: 15px;
        }
        p {
            font-weight: 500;
            margin-bottom: 20px;
        }
        h4 {
            font-size: 24px;
            color: $blue-color;
            margin-bottom: 0;

            i {
                display: inline-block;
                color: $blue-color;
                font-size: 10px;
                position: relative;
                top: -15px;
            }
        }
        img {
            position: absolute;
            right: -15px;
            bottom: -15px;
            border-radius: 50%;
            width: 130px !important;
            transition: $transition;
        }
    }
    .owl-prev {
        position: absolute;
        top: 38%;
        left: -40px;
        color: $white-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $pink-color !important;
            background-color: transparent !important;

            &:after {
                background-color: #fff;
            }
        }
        &:after {
            position: absolute;
            top: 11px;
            left: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ef4605;
            z-index: -1;
            transition: $transition;
        }
    }
    .owl-next {
        position: absolute;
        top: 38%;
        right: -40px;
        color: $white-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $pink-color !important;
            background-color: transparent !important;

            &:after {
                background-color: #fff;
            }
        }
        &:after {
            position: absolute;
            top: 11px;
            right: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ef4605;
            z-index: -1;
            transition: $transition;
        }
    }
    .feedback-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: -30px;
            }
            .owl-next {
                opacity: 1;
                right: -30px;
            }
        }
    }
}
/*-- End Feedback --*/

/*-- Blog --*/
.blog-item {
    margin-bottom: 30px;
    border: 1px dashed #c4c4c4;
    border-radius: 5px;
    padding: 15px 15px 25px 15px;
    overflow: hidden;
    transition: $transition;

    &:hover {
        transform: translate(0, -10px);
        box-shadow: 0px 0px 20px 0px #dddddd24;
        
        .top {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    .top {
        overflow: hidden;
        margin-bottom: 20px;
        border-radius: 5px;

        a {
            display: block;

            img {
                width: 100%;
                transition: $transition;
            }
        }
    }
    span {
        display: block;
        padding-left: 40px;
        margin-bottom: 20px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            width: 30px;
            height: 1px;
            left: 0;
            top: 11px;
            background-color: $grey-color;
        }
    }
    h3 {
        font-size: 20px;
        margin-bottom: 20px;

        a {
            color: $blue-color;

            &:hover {
                color: $pink-color;
            }
        }
    }
    .cmn-link {
        text-align: left;
        margin-left: 45px;

        a {
            color: $grey-color;

            i {
                color: $grey-color;
            }
            &:hover {
                margin-left: -45px;
                color: $pink-color;

                .one {
                    left: -60px;
                }
                .two {
                    color: $pink-color;
                }
            }
        }
    }
}
/*-- End Blog --*/

/*-- Partner --*/
.partner-area {
    background-color: #f8e8e9;
    position: relative;

    .partner-shape {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        img {
            height: 100px;
            width: 100%;
            transform: rotateX(180deg) rotateY(180deg);
        }
    }
    .col-lg-5 {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .partner-item {
        margin-bottom: 30px;
        text-align: center;
        position: relative;

        &:hover {
            img {
                &:nth-child(2) {
                    opacity: 0;
                    top: -30px;
                }
            }
        }
        img {
            width: 85px !important;
            height: 85px;
            margin-left: auto;
            margin-right: auto;

            &:nth-child(2) {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                opacity: 1;
                transition: $transition;
            }
        }
    }
}
/*-- End Partner --*/

/*-- App --*/
.app-area {
    background-image: url('assets/img/home-one/app-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .70;
    }
    .app-content {
        .section-title {
            margin-bottom: 25px;
    
            .sub-title {
                color: $white-color;
            }
            h2 {
                color: $white-color;
            }
        }
        ul {
            margin: 0;
            padding: 0;
            position: relative;
    
            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;
    
                &:last-child {
                    margin-right: 0;
                }
                a {
                    display: block;
                    border: 1px solid transparent;
                    border-radius: 10px;
                    border: 1px solid $white-color;
    
                    &:hover {
                        transform: translate(5px, 0);
                    }
                }
            }
        }
    }
    .app-img {
        text-align: center;
        position: relative;

        img {
            &:nth-child(1) {
                position: relative;
                z-index: 1;
                animation: app-ani 6s infinite linear;
            }
            &:nth-child(2) {
                position: absolute;
                left: 65px;
                right: 0;
                top: 70px;
                max-width: 380px;
            }
        }
    }
}
@keyframes app-ani {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
/*-- End App --*/

/*-- Footer --*/
.footer-item {
    margin-bottom: 30px;

    h3 {
        font-size: 20px;
        color: $blue-color;
        margin-bottom: 25px;
    }
    .footer-logo {
        .logo {
            display: block;
            margin-bottom: 25px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 10px;
                color: #676767;

                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    display: inline-block;
                    font-weight: 500;
                    color: $blue-color;
                    margin-right: 3px;
                }
                a {
                    display: inline-block;
                    color: #676767;

                    &:hover {
                        color: $pink-color;
                    }
                }
            }
        }
    }
    .footer-service {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    color: #676767;

                    &:hover {
                        color: $pink-color;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
    .footer-newsletter {
        p {
            color: #676767;
        }
        form {
            position: relative;

            .form-control {
                height: 55px;
                border-radius: 5px;
                border: 1px dashed #acacac;
                padding-left: 15px;
                transition: $transition;
                font-size: 15px;
    
                &:focus {
                    box-shadow: none;
                    border: 1px dashed $pink-color;
                }
            }
            .btn {
                padding: 0;
                border-radius: 0 5px 5px 0;
                color: $white-color;
                background-color: $pink-color;
                position: absolute;
                top: 5px;
                right: 5px;
                opacity: 1;
                transition: $transition;
                font-size: 22px;
                line-height: 20px;
                padding: 11px 16px 12px;

                &:hover {
                    background-color: $blue-color;
                }
            }
            .validation-danger {
                margin-top: 10px;
                color: #dc3545;
            }
            .validation-success {
                margin-top: 10px;
                color: #28a745;
            }
        }
    }
}
/*-- End Footer --*/

/*-- Copyright --*/
.copyright-area {
    background-color: #e6e6e6;
    padding-top: 25px;
    padding-bottom: 25px;

    .copyright-item {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;

                &:nth-child(2) {
                    a {
                        background-color: #55acee;
                    }
                }
                &:nth-child(3) {
                    a {
                        background-color: #3f729b;
                    }
                }
                &:nth-child(4) {
                    a {
                        background-color: #ff0000;
                    }
                }
                &:nth-child(5) {
                    a {
                        background-color: #c80000;
                        margin-bottom: 0;
                    }
                }
                a {
                    display: block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    color: $white-color;
                    background-color: #3b5998;
                    font-size: 18px;
                    border-radius: 50%;

                    i {
                        line-height: 30px;
                    }
                    &:hover {
                        transform: rotateY(360deg);
                    }
                }
            }
        }
        p {
            margin-bottom: 0;
            text-align: right;

            a {
                display: inline-block;
                color: $pink-color;
                font-weight: 600;
                border-bottom: 1px solid $pink-color;

                &:hover {
                    color: $grey-color;
                    border-bottom: 1px solid $grey-color;
                }
            }
        }
    }
}
/*-- End Copyright --*/
/*----- End Home Demo One CSS -----*/

/*----- Home Demo Two CSS -----*/
/*-- Navbar --*/
.main-nav.two {
    nav {
        .navbar-nav {
            .nav-item {
                &:hover {
                    a {
                        color: $green-color;
                    }
                }
                a {
                    color: $blue-color;

                    &:hover, &:focus, &.active {
                        color: $green-color;
                    }
                }
                .dropdown-menu {
                    li {
                        &:hover {
                            a {
                                color: $green-color;
                            }
                        }
                        a {   
                            color: $white-color;

                            &:before {
                                background-color: $green-color;
                            }
                            &:hover, &:focus, &.active {
                                color: $green-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: $white-color;

                                    &:hover, &:focus, &.active {
                                        color: $green-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .tooltip-span {
        background-color: $green-color;

        &:before {
            border-top: 7px solid $green-color;
        }
        &.two {
            background-color: $blue-color;

            &:before {
                border-top: 7px solid $blue-color;
            }
        }
    }
}
.side-nav.two {
    .job-right {
        background-color: $green-color;

        i {
            background-color: #17870a;
        }
        &:hover {
            background-color: #17870a
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.banner-area.two {
    background-color: #dffedb;
    overflow: hidden;

    &:before {
        display: none;
    }
    .container-fluid {
        padding-right: 50px;
    }
    .banner-shape-two {
        img {
            position: absolute;

            &:nth-child(1) {
                top: 0;
                right: 0;
            }
            &:nth-child(2) {
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
            }
        }
    }
}
.banner-content.two {
    height: 980px;

    p {
        margin-bottom: 10px;
        color: $blue-color;
    }
    h1 {
        font-size: 65px;
        max-width: 750px;
    }
    .banner-form-area {
        .btn {
            background-color: $green-color;

            i {
                background-color: #17870a;
            }
            &:hover {
                background-color: #17870a;
            }
        }
    }
    .banner-key {
        ul {
            li {
                span {
                    color: $white-color;
                    background-color: $green-color;
                }
            }
        }
    }
}
.banner-img-two {
    text-align: center;
    position: relative;

    img {
        box-shadow: 0px 0px 20px 0px #dddddd73;

        &:nth-child(1) {
            position: relative;
            box-shadow: none;
        }
        &:nth-child(2) {
            position: absolute;
            left: 10px;
            top: 215px;
            animation: banner-two-ani-one 5s infinite linear;
        }
        &:nth-child(3) {
            position: absolute;
            left: 110px;
            bottom: -10px;
            animation: banner-two-ani-three 5s infinite linear;
        }
        &:nth-child(4) {
            position: absolute;
            right: 5px;
            bottom: 65px;
            animation: banner-two-ani-two 5s infinite linear;
        }
    }
}
@keyframes banner-two-ani-one {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -15px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes banner-two-ani-two {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes banner-two-ani-three {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(15px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
/*-- End Banner --*/

/*-- Support --*/
.support-area {
    text-align: center;
    padding-top: 35px;

    p {
        font-weight: 600;
        font-family: $heading-font;
        font-size: 20px;
        margin-bottom: 50px;
        color: $blue-color;

        span {
            color: $green-color;
            border-bottom: 1px solid $green-color;
        }
    }
    .support-item {
        &:hover {
            img {
                opacity: 1;
            }
        }
        img {
            opacity: .60;
            transition: $transition;
            margin-left: auto;
            margin-right: auto;
            max-width: 125px;
        }
    }
    .support-slider {
        padding-right: 120px;

        .owl-prev {
            font-size: 30px !important;
            color: $grey-color !important;
            transition: $transition;

            &:hover {
                color: $green-color !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            font-size: 30px !important;
            color: $grey-color !important;
            transition: $transition;

            &:hover {
                color: $green-color !important;
                background-color: transparent !important;
            }
        }
    }
    .owl-carousel .owl-item img {
        width: unset;
    }
    .owl-theme .owl-nav {
        margin-top: 0;
        position: absolute;
        right: 0;
        left: 0;
        bottom: -5px;
        max-width: 200px;
        margin-left: auto;
        text-align: right;
    }
}
/*-- End Support --*/

/*-- Company --*/
.company-area.two {
    .section-title {
        text-align: center;
    }
    .owl-prev {
        position: absolute;
        top: 40%;
        left: 35px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $green-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 10px;
            left: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .owl-next {
        position: absolute;
        top: 40%;
        right: 35px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $green-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 10px;
            right: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .company-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: 45px;
            }
            .owl-next {
                opacity: 1;
                right: 45px;
            }
        }
    }
}
.company-item.two {
    margin-bottom: 0;

    &:hover {
        transform: unset;

        .bottom {
            a {
                color: $green-color;
            }
        }
    }
    .feature-top-right {
        span {
            background-color: $green-color;
        }
    }
    .bottom {
        h4 {
            color: $green-color;
        }
    }
    .top {
        a {
            img {
                height: 55px;
                width: 55px;
            }
        }
    }
}
/*-- End Company --*/

/*-- Explore --*/
.explore-area.two {
    background-color: #dffedb;
    background-image: unset;

    .container {
        position: relative;
    }
    &:before {
        display: none;
    }
    .explore-item {
        text-align: center;
        margin-left: 0;

        .section-title {
            .sub-title {
                color: $green-color;

                &:before {
                    background-color: $white-color;
                }
            }
            h2 {
                color: $blue-color;
            }
        }
        p {
            color: $grey-color;
        }
        ul {
            li {
                .left-btn {
                    color: $white-color;
                    background-color: $green-color;

                    i {
                        background-color: #17870a;
                    }
                    &:hover {
                        background-color: #17870a;
                    }
                }  
                span {
                    color: $blue-color;
                }
                .right-btn {
                    color: $green-color;
                    border: 1px solid $green-color;

                    &:hover {
                        color: $white-color;
                        background-color: $green-color;
                    }
                }
            }
        }      
    }
    .explore-img {
        position: absolute;
        top: -130px;
        right: 0;
        max-width: 600px;
        animation: explore-img-ani 5s infinite linear;
    }
}
@keyframes explore-img-ani {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(-25deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}
/*-- End Explore --*/

/*-- Categories --*/
.categories-area {
    .nav-pills {
        margin-bottom: 30px;

        a {
            font-weight: 500;
            font-size: 20px;
            color: $blue-color;
            padding: 15px 0;
            border-bottom: 1px solid #e4e4e4;
            position: relative;

            i {
                display: inline-block;
                margin-right: 5px;
            }
            .two {
                position: absolute;
                right: 0;
                top: 7px;
                margin-right: 0;
                font-size: 30px;
            }
            &.active {
                color: $green-color;
                background-color: transparent;
            }
        }
    }
    .col-lg-4 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .cat-item {
        margin-bottom: 30px;
        border: 2px dashed #dffedb;
        position: relative;
        border-radius: 5px;
        padding: 20px 10px 20px 60px;
        box-shadow: 0px 0px 20px 0px #dddddd4a;
        z-index: 1;
        transition: $transition;

        &:before {
            position: absolute;
            content: '';
            width: 35px;
            height: 35px;
            bottom: 0;
            right: 0;
            border-radius: 5px;
            background-color: #dffedb;
            border-radius: 50% 50% 0 50%;
            transition: $transition;
            z-index: -1;
        }
        &:hover {
            transform: translate(0, -10px);

            &:before {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
            .link {
                right: 20px;
            }
        }
        img {
            position: absolute;
            top: 25px;
            left: 15px;
        }
        h3 {
            margin-bottom: 5px;
            font-size: 16px;

            a {
                display: inline-block;
                color: $blue-color;
            }
        }
        span {
            display: block;
            font-size: 13px;
        }
        .link {
            display: inline-block;
            color: $blue-color;
            position: absolute;
            right: 15px;
            bottom: 2px;
            font-size: 30px;
            line-height: 30px;

            &:hover {
                color: $green-color;
            }
        }
    }
}
/*-- End Categories --*/

/*-- Work --*/
.section-title.two{
    .sub-title {
        &:before {
            background-color: $green-color;
        }
    }
}
.work-area.two {
    .section-title {
        text-align: center;
    }
}
.work-item.two {
    padding: 0 20px;
    background-color: $white-color;
    text-align: center;
    border-right: 1px dashed #d5d5d5;
    border-radius: 0;

    &:hover {
        h3 {
            color: $blue-color;
        }
        p {
            color: $grey-color;
        }
    }
    &:before {
        display: none;
    }
    &:after {
        position: absolute;
        content: '';
        bottom: 85px;
        right: -16px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 12px solid #21a212;
        transform: rotate(90deg);
    }
    i {
        color: $green-color;
        position: relative;
        top: 0;
        right: 0;
        line-height: 45px;
        margin-bottom: 20px;
    }
    &.work-border {
        border-right: 0;

        &:after {
            display: none;
        }
    }
}
/*-- End Work --*/

/*-- Jobs --*/
.job-area.two {
    position: relative;
    z-index: 1;

    .sorting-menu {
        ul {
            li {
                &:hover, &.active {
                    color: $green-color;
                }
            }
        }
    }
    .section-title {
        .sub-title {
            &:before {
                background-color: $white-color;
            }
        }
    }
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 370px;
        left: 0;
        top: 0;
        background-color: #dffedb;
        z-index: -1;
    }
    .job-item {
        box-shadow: 0px 0px 20px 0px #dddddd91;

        &:hover {
            border: 1px dashed $green-color;
            border-left: 2px solid $green-color;
            background-color: $white-color;
        }
        .job-left {
            h3 {
                a {
                    &:hover {
                        color: $green-color;
                    }
                }
            }
            ul {
                li {
                    color: $green-color;

                    &:nth-child(3) {
                        color: #7068a3;
                    }
                    &:nth-child(4) {
                        color: #7068a3;
                    }
                }
            }
        }
        .job-right {
            ul {
                li {
                    a {
                        &:hover {
                            color: $green-color;
                            background-color: #dffedb;
                        }
                    }
                }
            }
        }
    }
    .feature-top-right {
        span {
            background-color: $green-color;
        }
    }
    .job-browse {
        p {
            a {
                color: $green-color;
                border-bottom: 1px solid $green-color;

                &:hover {
                    color: $blue-color;
                    border-bottom: 1px solid $blue-color;
                }
            }
        }
    }
}
/*-- End Jobs --*/

/*-- Candidate --*/
.candidate-area {
    .section-title {
        text-align: center;
    }
    .candidate-browse {
        text-align: center;

        p {
            margin-bottom: 0;
            color: #7068a3;
            font-size: 17px;

            a {
                display: inline-block;
                color: $green-color;
                border-bottom: 1px solid $green-color;
                font-weight: 500;

                &:hover {
                    color: $blue-color;
                    border-bottom: 1px solid $blue-color;
                }
            }
        }
    }
    .owl-prev {
        position: absolute;
        top: 38%;
        left: -50px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $green-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 12px;
            left: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .owl-next {
        position: absolute;
        top: 38%;
        right: -50px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $green-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 12px;
            right: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .candidate-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: -40px;
            }
            .owl-next {
                opacity: 1;
                right: -40px;
            }
        }
    }
}
.candidate-item {
    border: 2px dashed #dffedb;
    padding: 25px 25px 25px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 30px;

    .left {
        max-width: 240px;

        h3 {
            font-size: 20px;
            margin-bottom: 5px;
    
            a {
                color: $blue-color;
    
                &:hover {
                    color: $green-color;
                }
            }
        }
        span {
            display: block;
            color: #7d789b;
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 25px;
        }
        ul {
            margin: 0;
            padding: 0;
    
            li {
                list-style-type: none;
                display: inline-block;
                font-size: 12px;
                color: #7f7f7f;
                background-color: #ececec;
                border-radius: 5px;
                padding: 4px 8px;
                margin-right: 3px;
                margin-bottom: 6px;
            }
        }
        .cmn-link {
            text-align: left;
            margin-left: 45px;
            margin-top: 22px;
    
            a {
                color: #7068a3;
                font-size: 15px;
    
                i {
                    color: #7068a3;
                    top: -4px;
                }
                &:hover {
                    margin-left: -45px;
                    color: $green-color;
    
                    .one {
                        left: -60px;
                    }
                    .two {
                        color: $green-color;
                    }
                }
            }
        }
    }
    img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-radius: 5px;
        max-width: 250px;
    }
}
/*-- End Candidate --*/

/*-- Testimonial --*/
.testimonial-area {
    background-image: url('assets/img/home-two/testimonial-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .owl-prev {
        position: absolute;
        top: 38%;
        left: -50px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $green-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 12px;
            left: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .owl-next {
        position: absolute;
        top: 38%;
        right: -50px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $green-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 12px;
            right: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $green-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .testimonial-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: -40px;
            }
            .owl-next {
                opacity: 1;
                right: -40px;
            }
        }
    }
    .section-title {
        margin-bottom: 30px;
        max-width: 830px;
        margin-left: auto;
    }
    .testimonial-item {
        position: relative;
        padding-left: 280px;

        img {
            position: absolute;
            top: 0;
            left: 10px;
            border-radius: 50%;
            border: 5px solid #fff;
            box-shadow: 0px 0px 20px 0px #dddddd8c;
            max-width: 200px;
        }
        p {
            font-size: 22px;
            margin-bottom: 35px;
            color: #64607c;
        }
        h3 {
            font-size: 20px;
            color: #414141;
            font-weight: 600;
        }
        span {
            display: block;
        }
    }
}
/*-- End Testimonial --*/

/*-- Blog --*/
.blog-area.two {
    .cmn-link {
        a {
            color: $green-color;

            i {
                color: $green-color;
            }
            &:hover {
                .two {
                    color: $green-color;
                }
            }
        }
    }
    .blog-item {
        h3 {
            a {
                &:hover {
                    color: $green-color;
                }
            }
        }
    }
}
/*-- End Blog --*/

/*-- Register --*/
.register-area {
    background-color: #dffedb;
    padding-top: 50px;
    padding-bottom: 20px;

    .container {
        position: relative;

        .row {
            padding-right: 390px;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                border: 1px solid transparent;
                border-radius: 15px;

                &:hover {
                    border: 1px solid $blue-color;
                }
            }
        }
    }
    .register-item {
        margin-bottom: 30px;
        padding-left: 30px;
        border-left: 1px dashed  #7068a3;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            top: 28px;
            left: -4px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 9px solid #21a212;
            transform: rotate(90deg);
        }
        h3 {
            font-size: 25px;
            color: $blue-color;
            margin-bottom: 4px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
/*-- End Register --*/

/*-- Footer --*/
.footer-item.two {
    .footer-logo {
        ul {
            li {
                a {
                    &:hover {
                        color: $green-color;
                    }
                }
            }
        }
    }
    .footer-service {
        ul {
            li {
                a {
                    &:hover {
                        color: $green-color;
                    }
                }
            }
        }
    }
    .footer-newsletter {
        form {
            .btn {
                background-color: $green-color;

                &:hover {
                    background-color: $blue-color;
                }
            }
        }
    }
}
/*-- End Footer --*/

/*-- Copyright --*/
.copyright-area.two {
    .copyright-item {
        p {
            a {
                color: $green-color;
                border-bottom: 1px solid $green-color;

                &:hover {
                    color: $blue-color;
                    border-bottom: 1px solid $blue-color;
                }
            }
        }
    }
}
/*-- End Copyright --*/
/*----- End Home Demo Two CSS -----*/

/*----- Home Demo Three CSS -----*/
/*-- Navbar --*/
.main-nav.three {
    nav {
        .navbar-nav {
            .nav-item {
                &:hover {
                    a {
                        color: $pink-color;
                    }
                }
                a {
                    color: $white-color;

                    &:hover, &:focus, &.active {
                        color: $pink-color;
                    }
                }
                .dropdown-menu {
                    li {
                        &:hover {
                            a {
                                color: $pink-color;
                            }
                        }
                        a {   
                            color: $white-color;

                            &:before {
                                background-color: $pink-color;
                            }
                            &:hover, &:focus, &.active {
                                color: $pink-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: $white-color;

                                    &:hover, &:focus, &.active {
                                        color: $pink-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .tooltip-span {
        color: $blue-color;
        background-color: $white-color;

        &:before {
            border-top: 7px solid $white-color;
        }
        &.two {
            color: $white-color;
            background-color: $pink-color;

            &:before {
                border-top: 7px solid $pink-color;
            }
        }
    }
    &.menu-shrink {
        background: #161616;
        box-shadow: 0px 0px 20px 0px #6363636b;
    }
}
.side-nav.three {
    .login-left {
        color: $white-color;

        i {
            color: $white-color;
        }
        &:hover {
            color: $pink-color;
        }
    }
    .job-right {
        background-color: $pink-color;

        i {
            background-color: #c02932;
        }
        &:hover {
            background-color: #c02932;
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.banner-area.three {
    background-color: #161616;

    &:before {
        display: none;
    }
    .banner-shape-three {
        img {
            position: absolute;
            bottom: 0;
            right: 40px;
            max-width: 749px;
        }
    }
    .banner-bottom-btn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -30px;
        max-width: 50px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        &:before {
            position: absolute;
            content: '';
            bottom: -30px;
            right: 0;
            left: -20px;
            width: 90px;
            border-left: 45px solid transparent;
            border-right: 45px solid transparent;
            border-bottom: 65px solid #161616;
            transform: rotate(180deg);
            z-index: -1;
        }
        a {
            color: $white-color;
            display: inline-block;
            font-size: 30px;
            position: relative;
            top: 24px;
            animation: banner-bottom-btn-ani 5s infinite linear;

            &:hover {
                color: $pink-color;
            }
        }
    }
}
@keyframes banner-bottom-btn-ani {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -10px);
    }
    100% {
        transform: translate(0, 0);
    }
}
.banner-content.three {
    height: 1075px;
    max-width: 800px;
    padding-top: 95px;

    p {
        color: $white-color;
    }
    h1 {
        color: $white-color;

        span {
            color: $pink-color;
        }
    }
    .banner-form-area {
        .btn {
            background-color: $pink-color;

            i {
                background-color: #c02932;
            }
            &:hover {
                background-color: #c02932;
            }
        }
    }
    .banner-key {
        ul {
            li {
                span {
                    padding: 0;
                    color: #fff;
                    background-color: transparent;
                }
                a {
                    &:hover {
                        color: $white-color;
                    }
                }
            }
        }
    }
    .register-area {
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 140px;

        .container {
            .row {
                padding-right: 0;
            }
        }
        .register-item {
            border-left: 1px dashed #babab9;

            &:before {
                border-bottom: 9px solid $pink-color;
            }
            h3 {
                color: $white-color;
            }
            p {
                color: #babab9;
                font-size: 15px;
            }
        }
    }
}
/*-- End Banner --*/

/*-- Employer --*/
.employer-area {
    padding-top: 155px;

    .section-title {
        text-align: center;
    }
}
.employer-item {
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px dashed $pink-color;
    box-shadow: 0px 0px 20px 0px #dddddd73;
    position: relative;
    padding: 25px 35px 25px 105px;
    transition: $transition;
    
    &:hover {
        transform: translate(0, -10px);
    }
    img {
        position: absolute;
        top: 25px;
        left: 25px;
    }
    h3 {
        margin-bottom: 7px;
        font-size: 20px;
        color: $blue-color;
    }
    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 15px;

        li {
            list-style-type: none;
            display: inline-block;
            color: #7d789b;
            margin-right: 10px;

            i {
                display: inline-block;
                font-size: 16px;
                position: relative;
                top: 1px;
                margin-right: 5px;
            }
            &:last-child {
                color: #00d2ff;
                font-size: 14px;
                margin-right: 0;
            }
        }
    }
    p {
        margin-bottom: 18px;
        color: $grey-color;
    }
    .span-one {
        color: #008aff;
        background-color: #e4e4e4;
        border-radius: 5px;
        padding: 4px 10px;
        font-size: 13px;
        display: inline-block;

        &.two {
            color: #c000ff;
        }
        &.three {
            color: #e20000;
        }
        &.four {
            color: #21a212;
        }
    }
    .span-two {
        color: #fff;
        background-color: #21a212;
        border-radius: 5px;
        padding: 4px 10px;
        font-size: 13px;
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;

        &.two {
            background-color: #a28c12;
        }
        &.three {
            background-color: #1271a2;
        }
        &.four {
            background-color: #e20000;
        }
    }
}
.section-title.three {
    .sub-title-wrap {
        position: relative;
        display: inline-block;

        img {
            position: absolute;
            top: 3px;
            left: 0;
        }
    }
    .sub-title {
        padding-left: 30px;

        &:before {
            display: none;
        }
    }
}
/*-- End Employer --*/

/*-- System --*/
.system-area {
    background-image: url('assets/img/home-three/system-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    .system-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                top: 0;
                right: 0;
                max-width: 420px;
            }
            &:nth-child(2) {
                top: 130px;
                right: 180px;
                max-width: 410px;
            }
        }
    }
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .70;
    }
    .system-item {
        position: relative;
        max-width: 660px;

        .section-title {
            margin-bottom: 20px;

            .sub-title {
                color: $white-color;
            }
            h2 {
                color: $white-color;
            }
        }
        p {
            color: #cbcbcb;
            margin-bottom: 25px;
        }
        .system-list {
            margin: 0;
            padding: 0;
            margin-bottom: 40px;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 20px;
                color: $white-color;

                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    border: 1px dashed $white-color;
                    border-radius: 50%;
                    position: relative;
                    top: 6px;
                    margin-right: 15px;

                    &:before {
                        position: absolute;
                        content: '';
                        top: 7px;
                        right: -10px;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-bottom: 9px solid $pink-color;
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .system-video {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                .left-btn {
                    background-color: $pink-color;
                    color: $white-color;
                    height: 50px;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 20px;
                    padding-right: 65px;
                    border-radius: 5px;
                    line-height: 50px;
                    display: inline-block;
                    position: relative;
                    margin-right: 20px;
            
                    i {
                        display: inline-block;
                        height: 50px;
                        line-height: 50px;
                        padding-left: 12px;
                        padding-right: 12px;
                        font-size: 20px;
                        color: $white-color;
                        background-color: #c02932;
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-radius: 0 5px 5px 0;
                    }
                    &:hover {
                        color: $white-color;
                        background-color: #c02932;
                    }
                }
                span {
                    display: inline-block;
                    color: $white-color;
                    margin-right: 20px;
                }
                .right-btn {
                    color: $white-color;
                    border: 1px solid $white-color;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 25px;
                    display: inline-block;
                    position: relative;
                    top: 4px;

                    i {
                        line-height: 40px;
                    }
                    &:hover {
                        color: $pink-color;
                        background-color: $white-color;
                    }
                }
            }
        }
    }
}
/*-- End System --*/

/*-- Work --*/
.work-area.three {
    .section-title {
        text-align: center;
    }
}
.work-item.three {
    i {
        color: $pink-color;
    }
    &:after {
        border-bottom: 12px solid $pink-color;
    }
}
/*-- End Work --*/

/*-- Feature --*/
.feature-area {
    .section-title {
        text-align: center;
    }
}
.feature-item {
    margin-bottom: 30px;
    text-align: center;
    border: 1px dashed $pink-color;
    background-color: #f8e8e9;
    border-radius: 5px;
    padding-top: 25px;
    transition: $transition;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        content: '';
        width: 92%;
        height: 10px;
        left: 0;
        right: 0;
        bottom: -10px;
        background-color: #ffc5c8;
        border-radius: 0 0 5px 5px;
        margin-left: auto;
        margin-right: auto;
        z-index: -1;
        transition: $transition;
        opacity: 0;
    }
    &:after {
        position: absolute;
        content: '';
        width: 82%;
        height: 20px;
        left: 0;
        right: 0;
        bottom: -20px;
        background-color: #ffdbdd;
        border-radius: 0 0 5px 5px;
        margin-left: auto;
        margin-right: auto;
        z-index: -2;
        transition: $transition;
        opacity: 0;
    }
    &:hover {
        transform: translate(0, -20px);
        background-color: $pink-color;

        &:before {
            opacity: 1;
        }
        &:after {
            opacity: 1;
        }
        .bottom {
            i {
                transform: rotateY(360deg);
            }
        }
    }
    a {
        display: block;

        img {
            margin-bottom: 30px;
            height: 200px;
        }
    }
    .bottom {
        text-align: left;
        background-color: $pink-color;
        border-radius: 5px;
        padding: 15px 10px 15px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        position: relative;
        z-index: 1;

        h3 {
            color: $white-color;
            font-size: 15px;
            margin-bottom: 3px;

            a {
                color: $white-color;

                &:hover {
                    color: $blue-color;
                }
            }
        }
        span {
            display: block;
            font-size: 14px;
            color: $white-color;
        }
        i {
            position: absolute;
            top: 15px;
            right: 5px;
            color: $white-color;
            font-size: 35px;
            transition: $transition;
        }
    }
}
/*-- End Feature --*/

/*-- Pricing --*/
.pricing-area {
    background-color: #fafafa;

    .section-title {
        text-align: center;
    }
}
.pricing-item {
    margin-bottom: 30px;
    background-color: $white-color;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px #dddddd4a;
    padding: 25px 25px 30px;
    text-align: center;
    transition: $transition;

    &:hover {
        transform: translate(0, -10px);
        background-color: #f8e8e9;
    }
    .top {
        margin-bottom: 15px;

        h3 {
            font-size: 20px;
            color: $blue-color;
            margin-bottom: 6px;
        }
        span {
            display: block;
            color: #7d789b;
            font-size: 14px;
            padding-bottom: 25px;
            border-bottom: 1px dashed #c9c9c9;
            position: relative;

            &:before{
                position: absolute;
                content: '';
                bottom: -1px;
                right: 0;
                left: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 12px solid #fe4a55;
                width: 15px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .middle {
        margin-bottom: 20px;

        h4 {
            font-size: 25px;
            color: $pink-color;
            border-bottom: 1px dashed #c9c9c9;    
            margin-bottom: 0;
            padding-bottom: 15px;

            .span-right {
                font-size: 15px;
                font-weight: 500;
            }
            .span-left {
                font-size: 18px;
            }
        }
    }
    .end {
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 25px;

            li {
                list-style-type: none;
                display: block;
                color: #767194;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
/*-- End Pricing --*/

/*-- Partner --*/
.partner-area.two {
    background-color: #fafafa;
}
/*-- End Partner --*/

/*-- Client --*/
.client-area {
    background-image: url('assets/img/home-two/testimonial-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    .client-img {
        img {
            position: absolute;
            border-radius: 50%;
            border: 5px solid $white-color;
            box-shadow: 0px 0px 20px 0px #dddddd4a;

            &:nth-child(1) {
                top: 50px;
                left: 185px;
                max-width: 100px;
            }
            &:nth-child(2) {
                top: 200px;
                left: 50px;
                max-width: 140px;
            }
            &:nth-child(3) {
                bottom: 80px;
                left: 300px;
            }
            &:nth-child(4) {
                top: 50px;
                right: 185px;
                max-width: 100px;
            }
            &:nth-child(5) {
                top: 200px;
                right: 50px;
                max-width: 140px;
            }
            &:nth-child(6) {
                bottom: 80px;
                right: 300px;
                max-width: 85px;

            }
        }
    }
    .section-title {
        text-align: center;
        margin-bottom: 40px;
    }
    .client-item {
        text-align: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        p {
            font-size: 22px;
            color: #64607c;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 20px;
            color: #414141;
            margin-bottom: 5px;
        }
        span {
            display: block;
        }
    }
    .owl-prev {
        position: absolute;
        top: 14%;
        left: -50px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $pink-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 10px;
            left: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $pink-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .owl-next {
        position: absolute;
        top: 14%;
        right: -50px;
        color: $blue-color !important;
        font-size: 35px !important;
        z-index: 1;
        transition: $transition;
        opacity: 0;

        &:hover {
            color: $pink-color !important;
            background-color: transparent !important;

            &:after {
                background-color: $blue-color;
            }
        }
        &:after {
            position: absolute;
            top: 10px;
            right: -12px;
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $pink-color;
            z-index: -1;
            transition: $transition;
        }
    }
    .client-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: -40px;
            }
            .owl-next {
                opacity: 1;
                right: -40px;
            }
        }
    }
}
/*-- End Client --*/

/*-- Blog --*/
.blog-area.three {
    background-color: #fafafa;

    .section-title {
        text-align: center;
    }
}
/*-- End Blog --*/

/*-- Footer --*/
.footer-area.three {
    background-color: #191918;
}
.footer-item.three {
    h3 {
        color: $white-color;
    }
    .footer-logo {
        ul {
            li {
                color: #afadad;

                a {
                    color: #afadad;
                }
                span {
                    color: $pink-color;
                }
            }
        }
    }
    .footer-service {
        ul {
            li {
                a {
                    color: #afadad;
                }
            }
        }
    }
    .footer-newsletter {
        p {
            color: #afadad;
        }
    }
}
/*-- End Footer --*/

/*-- Copyright --*/
.copyright-area.three {
    background-color: #292929;

    .copyright-item {
        p {
            color: $white-color;
        }
    }
}
/*-- End Copyright --*/
/*----- End Home Demo Three CSS -----*/

/*----- About CSS -----*/
.page-title-area {
    background-image: url('assets/img/page-title-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 400px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .70;
    }
    .title-item {
        position: relative;
        z-index: 1;
        text-align: center;
        margin-top: 70px;

        h2 {
            color: $white-color;
            font-size: 40px;
            margin-bottom: 12px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                color: $white-color;

                img {
                    display: inline-block;
                    margin-right: 3px;
                    position: relative;
                    top: -1px;
                }
                a {
                    display: inline-block;
                    color: $white-color;
                    
                    &:hover {
                        color: $pink-color;
                    }
                }
                span {
                    display: inline-block;
                    margin-left: 3px;
                    margin-right: 3px;
                    color: $white-color;
                }
            }
        }
    }
}
.cmn-btn {
    background-color: $pink-color;
    color: $white-color;
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 65px;
    border-radius: 5px;
    line-height: 50px;
    display: inline-block;
    position: relative;

    i {
        display: inline-block;
        height: 50px;
        line-height: 50px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 20px;
        color: $white-color;
        background-color: $blue-color;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 5px 5px 0;
    }
    &:hover {
        color: $white-color;
        background-color: $blue-color;
    }
}
.new-area {
    .new-img {
        position: relative;
        margin-bottom: 30px;

        img {
            &:nth-child(1) {
                position: relative;
                width: 100%;
                z-index: 1;
            }
            &:nth-child(2) {
                position: absolute;
                bottom: -20px;
                left: -20px;
            }
        }
    }
    .about-content {
        margin-bottom: 30px;

        .section-title {
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 10px;
        }
        .cmn-btn {
            margin-top: 25px;
        }
    }
}
.counter-area.two {
    &:before {
        display: none;
    }
}
.candidate-area.two {
    .owl-prev {
        &:after {
            background-color: $pink-color;
        }
        &:hover {
            color: $white-color !important;
        }
    }
    .owl-next {
        &:after {
            background-color: $pink-color;
        }
        &:hover {
            color: $white-color !important;
        }
    }
    .candidate-item {
        border: 2px dashed $pink-color;
        margin-bottom: 0;

        .left {
            .cmn-link {
                a {
                    &:hover {
                        color: $pink-color;

                        .two {
                            color: $pink-color;
                        }
                    }
                }
            }
        }
    }
}
/*----- End About CSS -----*/

/*----- Jobs CSS -----*/
.pagination-area {
    text-align: center;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 3px;
            margin-left: 3px;

            a {
                text-align: center;
                padding: 5px 15px;
                border-radius: 5px;
                border: 1px dashed $pink-color;
                display: inline-block;
                color: $pink-color;

                &:hover {
                    color: $white-color;
                    background-color: $pink-color;
                }
            }
        }
    }
}
.job-area-list {
    .job-list-item {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        li {
            list-style-type: none;
            display: inline-block;
            flex: 0 0 60%;
            max-width: 60%;

            &:last-child {
                flex: 0 0 40%;
                max-width: 40%;
                text-align: right;

                .sort {
                    display: inline-block;
                    margin-right: 8px;
                    position: relative;
                    top: -16px;
                }
                form {
                    display: inline-block;
                }
                .form-group {
                    margin-bottom: 0;
                    display: inline-block;

                    .nice-select {
                        height: 45px;
                        border-radius: 5px;
                        border: 1px dashed $pink-color;
                        padding-left: 15px;
                        font-size: 14px;
                        line-height: 45px;
                        display: block;
                        width: 100%;

                        .list { 
                            .option {
                                display: block;
                                width: 100%;
                                flex: 0 0 100%;
                                max-width: 100%;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            a {
                display: inline-block;
                font-weight: 500;
                font-size: 16px;
                color: $blue-color;
                margin-right: 25px;

                span {
                    color: $pink-color;
                }
                &:hover {
                    color: $pink-color;
                }
                &.rss {
                    color: $pink-color;
                    margin-right: 0;

                    i {
                        display: inline-block;
                        font-size: 20px;
                        position: relative;
                        top: 2px;
                        margin-right: 5px;
                    }
                    &:hover {
                        color: $blue-color;
                    }
                }
            }
        }
    }
    .job-list-right {
        h3 {
            font-size: 20px;
            margin-bottom: 20px;
            color: $blue-color;
        }
        .job {
            padding: 22px 25px 25px;
            border-radius: 5px;
            border: 1px dashed $pink-color;
            margin-bottom: 30px;

            form {
                position: relative;

                label {
                    position: absolute;
                    margin-bottom: 0;
                    font-size: 17px;
                    top: 10px;
                    left: 15px;
                }
                .form-control {
                    height: 45px;
                    border-radius: 5px;
                    border: 1px solid #e1e1e1;
                    font-size: 14px;
                    padding-left: 40px;
    
                    &:focus {
                        box-shadow: none;
                        border: 1px solid $pink-color;
                    }
                }
                .btn {
                    text-align: center;
                    width: 100%;
                    display: block;
                    color: $white-color;
                    background-color: $pink-color;
                    transition: $transition;
                    padding: 10px;
                    height: 45px;
                    font-weight: 500;
                    margin-top: 10px;

                    &:hover {
                        background-color: $blue-color;
                    }
                }
            }
        }
        .job-list-all {
            padding: 22px 25px 25px;
            border-radius: 5px;
            border: 1px dashed $pink-color;

            .search {
                border-bottom: 1px solid #e9e9e9;

                form {
                    position: relative;
                    margin-bottom: 30px;

                    .form-control {
                        height: 45px;
                        border-radius: 5px;
                        border: 1px solid #e1e1e1;
                        font-size: 14px;
                        padding-left: 15px;
        
                        &:focus {
                            box-shadow: none;
                            border: 1px solid $pink-color;
                        }
                    }
                    .btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        transition: $transition;
                        color: $white-color;
                        background-color: $pink-color;
                        padding: 0;
                        height: 45px;
                        padding-left: 12px;
                        padding-right: 12px;
                        font-size: 20px;

                        &:hover {
                            background-color: $blue-color;
                        }
                    }
                }
                .job-cmn-cat {
                    overflow-y: scroll;
                    height: 220px;
                }
                ::-webkit-scrollbar {
                    width: 10px;
                }
                ::-webkit-scrollbar-track {
                    background: #e1e1e1; 
                    border-radius: 30px;
                }
                ::-webkit-scrollbar-thumb {
                    background: #acacac; 
                    border-radius: 30px;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background: $pink-color; 
                }
            }
            .cmn-padding {
                padding-top: 22px;
                padding-bottom: 25px;
                border-top: 1px solid #e9e9e9;
            }
            .location {
                form {
                    position: relative;

                    .form-group {
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                        .icon {
                            position: absolute;
                            margin-bottom: 0;
                            font-size: 13px;
                            top: 10px;
                            left: 15px;
                            border: 1px solid #e1e1e1;
                            border-radius: 50%;
                            width: 25px;
                            height: 25px;
                            line-height: 25px;
                            text-align: center;
                        }
                        .form-control {
                            height: 45px;
                            border-radius: 5px;
                            border: 1px solid #e1e1e1;
                            font-size: 14px;
                            padding-left: 45px;
            
                            &:focus {
                                box-shadow: none;
                                border: 1px solid $pink-color;
                            }
                        }
                        .range {
                            margin-bottom: 5px;
                            color: $pink-color;
                            font-weight: 500;
                        }
                    }
                }
            }
            .salary {
                .form-group {
                    margin-bottom: 0;

                    .range {
                        color: $pink-color;
                        font-weight: 500;
                        margin-top: 15px;
                    }
                }
            }
            .level {
                padding-top: 22px;
                border-top: 1px solid #e9e9e9;

                .btn {
                    margin-top: 20px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    display: block;
                    width: 100%;
                    color: $white-color;
                    background-color: $pink-color;
                    border-radius: 5px;
                    transition: $transition;

                    &:hover {
                        background-color: $blue-color;
                    }
                }
            }
        }
        .alzo-contact {
            background-color: #c02932;
            padding-top: 25px;
            text-align: center;
            border-radius: 5px;
            margin-top: 30px;

            img {
                padding-bottom: 30px;
            }
            .bottom {
                background-color: $pink-color;
                padding-top: 30px;
                padding-bottom: 25px;
                border-radius: 0 0 5px 5px;

                span {
                    display: block;
                    color: $white-color;
                    margin-bottom: 5px;
                }
                h3 {
                    font-size: 40px;
                    margin-bottom: 0;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $blue-color;
                        }
                    }
                }
            }
        }
    }
    .flex-divide {
        display: flex;
        flex-wrap: wrap;

        .left {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0;
            padding: 0;
        }
        .right {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0;
            padding: 0;
        }
    }
    .job-cmn-cat {
        margin: 0;
        padding: 0;
    
        li {
            list-style-type: none;
            display: block;
            color: #7d789b;
            margin-bottom: 15px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/*----- End Jobs CSS -----*/

/*----- Job Details CSS -----*/
.page-title-area.two {
    .d-table-cell {
        vertical-align: bottom;
    }
    .left {
        margin-bottom: 30px;

        img {
            margin-bottom: 15px;
        }
        h2 {
            margin-bottom: 15px;
            color: $white-color;
            font-size: 35px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                color: #d9d9d9;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                    color: #00d2ff;

                    i {
                        color: #d9d9d9;
                    }
                }
                i {
                    display: inline-block;
                    font-size: 20px;
                    position: relative;
                    top: 3px;
                    margin-right: 3px;
                }
            }
        }
    }
    .right {
        text-align: center;
        margin-bottom: 30px;

        .cmn-btn {
            padding-left: 35px;
            padding-right: 85px;
            margin-bottom: 15px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                padding-right: 10px;
                border-right: 1px solid $white-color;
                margin-right: 10px;

                &:last-child {
                    border-right: 0;
                    margin-right: 0;
                    padding-right: 0;
                }
                a {
                    display: block;
                    color: $white-color;

                    i {
                        display: inline-block;
                        font-size: 17px;
                        position: relative;
                        top: 2px;
                        margin-right: 3px;
                    }
                    &:hover {
                        color: $pink-color;
                    }
                }
            }
        }
    }
}
.job-details-area {
    .details-item {
        .details-inner {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
            h3 {
                font-size: 22px;
                color: $blue-color;
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 0;
            }
            .job-details-slider {
                margin-top: 20px;

                .slider-item {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #000;
                        opacity: .40;
                    }
                    img {
                        border-radius: 5px;
                    }
                }
            }
            .owl-prev {
                position: absolute;
                top: 40%;
                left: 35px;
                color: $white-color !important;
                font-size: 35px !important;
                z-index: 1;
                transition: $transition;
                opacity: 0;
        
                &:hover {
                    color: $pink-color !important;
                    background-color: transparent !important;
        
                    &:after {
                        background-color: #fff;
                    }
                }
                &:after {
                    position: absolute;
                    top: 12px;
                    left: -12px;
                    content: '';
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #ef4605;
                    z-index: -1;
                    transition: $transition;
                }
            }
            .owl-next {
                position: absolute;
                top: 40%;
                right: 35px;
                color: $white-color !important;
                font-size: 35px !important;
                z-index: 1;
                transition: $transition;
                opacity: 0;
        
                &:hover {
                    color: $pink-color !important;
                    background-color: transparent !important;
        
                    &:after {
                        background-color: #fff;
                    }
                }
                &:after {
                    position: absolute;
                    top: 12px;
                    right: -12px;
                    content: '';
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #ef4605;
                    z-index: -1;
                    transition: $transition;
                }
            }
            .job-details-slider {
                &:hover {
                    .owl-prev {
                        opacity: 1;
                        left: 45px;
                    }
                    .owl-next {
                        opacity: 1;
                        right: 45px;
                    }
                }
            }
            ul {
                margin: 0;
                padding: 0;
                margin-top: 20px;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        display: inline-block;
                        color: $pink-color;
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }
}
.video-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    a {
        z-index: 10;
        display: block;
        width: 100px;
        height: 100px;
        line-height: 110px;
        border-radius: 50%;
        position: relative;
        font-size: 60px;
        text-align: center;
        color: $white-color;

        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 100px;
            height: 100px;
            background-color: $pink-color;
            border-radius: 50%;
            z-index: -1;
            animation: pulse-border 1500ms ease-out infinite;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 100px;
            height: 100px;
            background-color: $pink-color;
            border-radius: 50%;
            z-index: -1;
            transition: $transition;
        }
        &:hover {
            color: $white-color;

            &:before {
                background-color: $blue-color;
            }
            &:after {
                background-color: $blue-color;
            }
        }
    }
}
@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}
.widget-area {
    .widget-item {
        border-radius: 5px;
        border: 1px dashed $pink-color;
        padding: 25px 30px 30px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
        h3 {
            color: $blue-color;
            font-size: 20px;
            margin-bottom: 30px;
            position: relative;
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 15px;

            &:before {
                position: absolute;
                content: '';
                width: 50px;
                height: 3px;
                bottom: -2px;
                left: 0;
                background-color: $pink-color;
                border-radius: 0 50% 50% 0;
            }
        }
    }
    .job {
        form {
            position: relative;

            label {
                margin-bottom: 0;
                position: absolute;
                top: 13px;
                left: 15px;
            }
            .form-control {
                height: 45px;
                border-radius: 5px;
                border: 1px solid #e1e1e1;
                padding-left: 38px;
                font-size: 14px;
                transition: $transition;
                margin-bottom: 15px;

                &:focus {
                    box-shadow: none;
                    border: 1px solid $pink-color;
                }
            }
            .btn {
                width: 100%;
                display: block;
                border-radius: 5px;
                height: 45px;
                color: $white-color;
                background-color: $pink-color;
                transition: $transition;

                &:hover {
                    background-color: $blue-color;
                }
            }
        }
    }
    .information {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                position: relative;
                padding-left: 50px;
                border-bottom: 1px solid #e1e1e1;
                padding-bottom: 20px;
                margin-bottom: 20px;

                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: 0;
                }
                img {
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
                h4 {
                    font-size: 16px;
                    color: $blue-color;
                    margin-bottom: 5px;
                }
                span {
                    display: block;
                    color: #7d789b;
                    font-size: 14px;
                }
            }
        }
    }
    .download {
        padding: 30px 30px 30px;

        a {
            display: block;
            color: $white-color;
            background-color: $pink-color;
            width: 100%;
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: center;
            border-radius: 5px;
            font-weight: 500;
            margin-bottom: 10px;

            &:hover {
                background-color: $blue-color;
            }
            &.right {
                background-color: $blue-color;
                margin-bottom: 0;

                &:hover {
                    background-color: $pink-color;
                }
            }
        }
    }
    .owner {
        .top {
            position: relative;
            padding: 30px 25px 30px;
            background-color: #f8e8e9;
            padding-left: 105px;
            border: 1px dashed $pink-color;
            border-radius: 5px 5px 0 0;

            img {
                position: absolute;
                top: 25px;
                left: 30px;
            }
            h3 {
                color: $blue-color;
                font-size: 20px;
                margin-bottom: 8px;
            }
            i {
                color: $grey-color;
                display: inline-block;
                margin-right: 5px;
                font-size: 18px;
                position: relative;
                top: 1px;
            }
            span {
                display: inline-block;
            }
        }
        .widget-item {
            border-radius: 0 0 5px 5px;
            border-top: 0;
            
            a {
                display: block;
                color: $grey-color;

                &:hover {
                    color: $pink-color;
                }
            }
        }
    }
    .cat {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                position: relative;
                display: block;
                margin-bottom: 15px;

                &:before {
                    position: absolute;
                    content: '';
                    width: 165px;
                    height: 1px;
                    bottom: 11px;
                    right: 35px;
                    background-color: #8d8d8d3b;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    color: $grey-color;

                    &:hover {
                        color: $pink-color;
                    }
                }
                span {
                    display: block;
                    color: $pink-color; 
                    font-weight: 500;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
    .recent {
        .recent-inner {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
    
                li {
                    list-style-type: none;
                    display: block;
                    flex: 0 0 30%;
                    max-width: 30%;
    
                    &:last-child {
                        flex: 0 0 60%;
                        max-width: 60%;
                        margin-left: 15px;
                    }
                    img {
                        max-width: 75px;
                    }
                    span {
                        display: block;
                        color: $pink-color;
                        font-size: 13px;
                        margin-bottom: 4px;
                    }
                    a {
                        display: block;
                        color: $blue-color;
                        margin-bottom: 4px;
                        font-size: 15px;
                        font-weight: 500;
    
                        &:hover {
                            color: $pink-color;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .tags {
        h3 {
            margin-bottom: 25px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 7px;
                margin-top: 5px;

                a {
                    display: block;
                    font-weight: 500;
                    color: $grey-color;

                    &:hover {
                        color: $pink-color;
                    }
                }
            }
        }
    }
}
.job-details-related {
    background-color: #fafafa;

    .section-title {
        margin-bottom: 30px;

        h2 {
            font-size: 25px;
        }
    }
}
/*----- End Job Details CSS -----*/

/*----- Post A Job CSS -----*/
.post-job-area {
    .post-item {
        text-align: center;
        background-color: #f5f5f5;
        padding: 40px 50px 50px;
        border-radius: 5px;

        .section-title {
            max-width: 670px;
            margin-left: auto;
            margin-right: auto;

            h2 {
                font-size: 30px;
                margin-bottom: 25px;
                position: relative;
                padding-bottom: 10px;

                &:before {
                    position: absolute;
                    content: '';
                    width: 55px;
                    height: 3px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $pink-color;
                }
                &:after {
                    position: absolute;
                    content: '';
                    width: 35px;
                    height: 3px;
                    left: 0;
                    right: 0;
                    bottom: -8px;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $pink-color;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
        .form-group {
            margin-bottom: 30px;
            text-align: left;

            label {
                margin-bottom: 10px;
                font-weight: 500;
            }
            .form-control {
                padding-left: 20px;
                height: 50px;
                border: 1px solid $white-color;
                font-size: 14px;
                transition: $transition;

                &:focus {
                    box-shadow: none;
                    border: 1px solid $pink-color;
                }
            }
            ::placeholder {
                color: rgb(163, 163, 163);
            }
            .nice-select {
                width: 100%;
                display: block;
                padding-left: 20px;
                height: 50px;
                border: 1px solid $white-color;
                font-size: 14px;
                transition: $transition;
                line-height: 50px;
                margin-bottom: 30px;

                .list {
                    display: block;
                    width: 100%;
                }
            }
        }
        .btn {
            color: $white-color;
            background-color: $pink-color;
            border-radius: 5px;
            text-align: center;
            display: inline-block;
            padding: 12px 100px;
            font-weight: 500;
            transition: $transition;

            &:hover {
                background-color: $blue-color;
            }
        }
    }
}
/*----- End Post A Job CSS -----*/

/*----- Candidates CSS -----*/
.job-filter-area {
    .form-group {
        margin-bottom: 30px;

        .form-control {
            height: 50px;
            border: 1px dashed $pink-color;
            padding-left: 15px;
            transition: $transition;
            font-size: 14px;

            &:focus {
                box-shadow: none;
                border: 1px dashed $blue-color;
            }
        }
        .nice-select {
            height: 50px;
            border: 1px dashed $pink-color;
            padding-left: 15px;
            transition: $transition;
            font-size: 14px;
            display: block;
            width: 100%;
            line-height: 50px;
            margin-bottom: 30px;

            .list {
                width: 100%;
                display: block;
            }
        }
    }
    .cmn-btn {
        border: 0;
        transition: $transition;
        width: 100%;
        display: block;
        margin-bottom: 30px;
    }
}
.job-showing-area {
    h4 {
        font-size: 20px;
        color: $blue-color;
        margin-bottom: 20px;
    }
    .showing {
        background-color: #f8e8e9;
        padding: 15px 15px;
        border-radius: 5px;
        margin-bottom: 50px;

        .left {
            .form-group {
                margin-bottom: 0;

                .nice-select {
                    height: 45px;
                    line-height: 45px;
                    border-radius: 5px;
                    padding-left: 15px;
                    border: 0;
                    width: 200px;
                    display: block;
                    
                    .list {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
        .right {
            ul {
                margin: 0;
                padding: 0;
                text-align: right;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-left: 8px;

                    a {
                        display: block;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        color: $blue-color;
                        background-color: $white-color;
                        border-radius: 5px;
                        text-align: center;
                        font-size: 30px;

                        i {
                            line-height: 40px;
                        }
                        &:hover {
                            color: $white-color;
                            background-color: $pink-color;
                        }
                    }
                }
            }
        }
    }
}
.candidate-item.two {
    border: 2px dashed $pink-color;
    overflow: hidden;

    .experience {
        margin: 0;
        padding: 0;
        margin-top: 14px;
        margin-bottom: 18px;

        li {
            list-style-type: none;
            display: block;
            color: #595959;
            font-size: 14px;
            margin-bottom: 5px;
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            margin-right: 0;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                display: inline-block;
                margin-bottom: 0;
                color: $pink-color;
            }
            i {
                color: $pink-color;
                display: inline-block;
                margin-right: 3px;
            }
        }
    }
    .left {
        .cmn-link {
            margin-top: 10px;

            a {
                &:hover {
                    color: $pink-color;

                    .two {
                        color: $pink-color;
                    }
                }
            }
        }
    }
    img {
        border-radius: 5px;
        width: 100%;
    }
}
/*----- End Candidates CSS -----*/

/*----- Candidate Details CSS -----*/
.page-title-area.two {
    .left.two {
        position: relative;
        padding-left: 255px;

        img {
            position: absolute;
            bottom: -75px;
            left: 0;
            border-radius: 50%;
            border: 5px solid #fff;
            box-shadow: 0px 0px 20px 0px #dddddd87;
        }
        ul {
            li {
                a {
                    display: inline-block;
                    color: $pink-color;

                    &:hover {
                        color: #d9d9d9;
                    }
                }
            }
        }        
    }
}
.person-details-area {
    .details-item {
        h3 {
            color: $blue-color;
            font-size: 25px;
            margin-bottom: 20px;
        }
        .profile {
            margin-bottom: 50px;

            p {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .bottom-item {
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #f5f5f5;
        }
        .work {
            ul {
                margin: 0;
                padding: 0;
                padding-top: 5px;
                margin-bottom: 10px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    color: $pink-color;
                    font-weight: 500;

                    img {
                        display: inline-block;
                        margin-right: 5px;
                        position: relative;
                        top: -2px;
                    }
                    span {
                        color: $grey-color;
                        font-weight: 400;
                        margin-left: 15px;
                    }
                }
            }
            h4 {
                color: $blue-color;
                font-size: 18px;
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .bottom-item-last {
            margin-bottom: 50px;
        }
        .skills {
            margin-bottom: 50px;

            .skill-wrap {
                overflow: hidden;
                padding-right: 15px;

                .skill {
                    margin-bottom: 35px;
                    position: relative;
                        
                    &:before{
                        width: 100%;
                        height: 3px;
                        content: "";
                        display: block;
                        position: absolute;
                        background: #eaeaea;
                        bottom: 0;
                    }
                    h3 {
                        font-size: 16px;
                        font-weight: 600;
                        color: #0d1820;
                        margin-bottom: 15px;
                    }
                    &:last-child {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .skill-bar {
            width: 100%;
            height: 3px;
            background: $pink-color;
            display: block;
            position: relative;
    
            &:before {
                position: absolute;
                content: '';
                top: -4px;
                right: -2px;
                width: 10px;
                height: 10px;
                border-radius: 12px;
                background-color: $pink-color;
            }
            span{
                position: absolute;
                top: -35px;
                padding: 0;
                font-size: 16px;
                font-weight: 500;
                color: #0d1820;
                margin-bottom: 0;
                display: inline-block;
            }
        }
        .skill1 {
            width: 56%;  
    
            .skill-count1 {
                right: 7px;
            }
        }
        .skill2 {
            width: 80%;
    
            .skill-count2 {
                right: 7px;
            }
        }
        .skill3 {
            width: 90%;
    
            .skill-count3 {
                right: 7px;
            }
        }
        .skill4 {
            width: 70%;
    
            .skill-count4 {
                right: 7px;
            }
        }
        .skill5 {
            width: 80%;
    
            .skill-count5 {
                right: 7px;
            }
        }
        .skill6 {
            width: 90%;
    
            .skill-count6 {
                right: 7px;
            }
        }
        .portfolio {
            .img {
                margin-bottom: 30px;

                img {
                    border-radius: 5px;
                }
            }
        }
        .client {
            border: 1px dashed $pink-color;
            border-radius: 5px;
            background-color: #f8e8e9;
            padding: 20px 20px 20px 155px;
            position: relative;
            margin-bottom: 30px;

            img {
                position: absolute;
                top: 20px;
                left: 20px;
                border-radius: 50%;
            }
            h3 {
                color: #1b2b37;
                font-size: 20px;
                margin-bottom: 5px;
            }
            span {
                display: block;
                font-size: 14px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 0;
            }
            ul {
                margin: 0;
                padding: 0;
                position: absolute;
                top: 20px;
                right: 20px;

                li {
                    list-style-type: none;
                    display: inline-block;

                    span {
                        color: $white-color;
                        background-color: $pink-color;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        border-radius: 50%;
                        margin-bottom: 0;
                        margin-right: 5px;
                    }
                    i {
                        display: inline-block;
                        color: #ddd;
                    }
                    .checked {
                        color: #ed4800;
                    }
                }
            }
        }
        .review {
            background-color: #f4f4f4;
            border-radius: 5px;
            padding: 28px 25px 35px;

            h3 {
                color: #1c2522;
                margin-bottom: 5px;
                font-size: 20px;
            }
            span {
                display: block;
                margin-bottom: 8px;
            }
            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 20px;

                li {
                    list-style-type: none;
                    display: inline-block;

                    span {
                        color: $white-color;
                        background-color: $pink-color;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        border-radius: 50%;
                        margin-bottom: 0;
                        margin-right: 5px;
                    }
                    i {
                        display: inline-block;
                        color: #ddd;
                    }
                    .checked {
                        color: #ed4800;
                    }
                }
            }
            .form-group {
                margin-bottom: 30px;
                position: relative;

                i {
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    font-size: 21px;
                }
                .form-control {
                    height: 50px;
                    border-radius: 5px;
                    padding-left: 40px;
                    border: 1px solid #e0e0e0;
                    font-size: 14px;
                    transition: $transition;

                    &:focus {
                        border: 1px solid $pink-color;
                        box-shadow: none;
                    }
                }
                textarea {
                    height: auto !important;
                    padding-top: 13px;
                }
            }
            .btn {
                color: $white-color;
                background-color: $pink-color;
                transition: $transition;
                padding: 12px 40px;
                border-radius: 5px;
                font-weight: 500;

                &:hover {
                    background-color: $blue-color;
                }
            }
        }
    }
    .owner {
        margin-bottom: 30px;
    }
    .similar-candidates {
        h4 {
            font-size: 20px;
            margin-bottom: 15px;
            color: $blue-color;
        }
    }
    .candidate-item {
        overflow: hidden;
        border: 1px dashed $pink-color;

        &:last-child {
            margin-bottom: 0;
        }
        .cmn-link {
            a {
                &:hover {
                    color: $pink-color;

                    .two {
                        color: $pink-color;
                    }
                }
            }
        }
    }
}
/*----- End Candidate Details CSS -----*/

/*----- Blog Details CSS -----*/
.blog-details-area {
    .details-item {
        .details-img {
            margin-bottom: 30px;

            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 20px;

                li {
                    list-style-type:  none;
                    display: inline-block;
                    margin-right: 12px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        color: $grey-color;

                        &:hover {
                            color: $pink-color;
                        }
                    }
                    i {
                        display: inline-block;
                        color: $pink-color;
                        margin-right: 3px;
                        position: relative;
                        top: 2px;
                        font-size: 16px;
                    }
                    span {
                        display: inline-block;
                    }
                }
            }
            img {
                display: block;
                width: 100%;
                margin-bottom: 20px;
            }
            h2 {
                font-size: 35px;
                color: $blue-color;
                margin-bottom: 20px;
            }
            .d-content {
                position: relative;
                margin-bottom: 10px;

                span {
                    display: inline-block;
                    font-size: 90px;
                    color: $blue-color;
                    font-weight: 500;
                    line-height: 75px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                p {
                    margin-bottom: 0 !important;
                    padding-left: 80px;
                }
            }
            p {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            blockquote {
                border-radius: 5px;
                border: 1px dashed $pink-color;
                background-color: #f8e8e9;
                text-align: center;
                padding: 22px 60px 25px;
                margin-bottom: 28px;
                margin-top: 30px;

                i {
                    display: block;
                    color: $pink-color;
                    font-size: 50px;
                    margin-bottom: 10px;
                }
                p {
                    font-weight: 500;
                    font-size: 20px;
                    margin-bottom: 15px;
                    color: $blue-color;
                }
                span {
                    display: inline-block;
                    font-size: 14px;
                    color: $pink-color;
                    position: relative;
                    padding-left: 25px;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 35px;
                        height: 1px;
                        left: -20px;
                        bottom: 6px;
                        background-color: $pink-color;
                    }
                }
            }
            h3 {
                font-size: 25px;
                color: $blue-color;
                margin-bottom: 5px;
            }
        }
        .details-tag {
            background-color: #f8e8e9;
            border: 1px dashed $pink-color;
            padding: 20px 20px 20px;
            border-radius: 5px;
            margin-bottom: 30px;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                }
            }
            .left {
                ul {
                    li {
                        span {
                            display: inline-block;
                            font-weight: 500;
                            font-size: 17px;
                            color: $blue-color;
                            margin-right: 8px;
                        }
                        a {
                            display: inline-block;
                            color: $pink-color;
                            margin-right: 8px;

                            &:hover {
                                color: $blue-color;
                            }
                        }
                    }
                }
            }
            .right {
                text-align: right;

                ul {
                    li {
                        margin-right: 8px;

                        &:last-child {
                            margin-right: 0;
                        }
                        a {
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            text-align: center;
                            border-radius: 50%;
                            font-size: 16px;
                            color: $white-color;
                            background-color: #3b5998;
                            
                            i {
                                line-height: 30px;
                            }
                            &:hover {
                                transform: rotateY(360deg);
                            }
                        }
                        &:nth-child(2) {
                            a {
                                background-color: #55acee;
                            }
                        }
                        &:nth-child(3) {
                            a {
                                background-color: #3f729b;
                            }
                        }
                        &:nth-child(4) {
                            a {
                                background-color: #ff0000;
                            }
                        }
                        &:nth-child(5) {
                            a {
                                background-color: #c80000;
                            }
                        }
                    }
                }
            }
        }
        .details-date {
            padding-bottom: 20px;

            .date-inner {
                background-color: #f8e8e9;
                border: 1px dashed $pink-color;
                border-radius: 5px;
                padding: 15px 40px 15px 25px;
                position: relative;
                transition: $transition;
                margin-bottom: 30px;
                
                &:hover {
                    a {
                        opacity: 1;
                    }
                }
                span {
                    font-size: 14px;
                    color: $pink-color;
                    padding-left: 50px;
                    position: relative;
                    margin-bottom: 10px;
                    display: block;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 35px;
                        height: 1px;
                        bottom: 7px;
                        left: 0;
                        background-color: $pink-color;
                    }
                }
                h4 {
                    margin-bottom: 0;
                    color: $blue-color;
                    font-size: 18px;
                }
                a {
                    display: inline-block;
                    color: $white-color;
                    font-size: 35px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 0 5px 5px 0;
                    background-color: $pink-color;
                    height: 100%;
                    padding-left: 6px;
                    padding-right: 6px;
                    opacity: 0;

                    i {
                        line-height: 110px;
                    }
                    &:hover {
                        background-color: $blue-color;
                    }
                }
            }
        }
        .details-comment {
            margin-bottom: 50px;

            h3 {
                font-size: 20px;
                color: $blue-color;
                margin-bottom: 30px;
                position: relative;
                padding-bottom: 10px;

                &:before {
                    position: absolute;
                    content: '';
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: $pink-color;
                }
                span {
                    display: inline-block;
                    color: $grey-color;
                    font-weight: 400;
                }
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    position: relative;
                    padding-left: 130px;
                    border-bottom: 1px solid #ededed;
                    padding-bottom: 30px;
                    margin-bottom: 30px;

                    &:last-child {
                        padding-bottom: 0;
                        margin-bottom: 0;
                        border-bottom: 0;
                        margin-left: 40px;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    h4 {
                        color: $blue-color;
                        font-size: 20px;
                        margin-bottom: 5px;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        margin-bottom: 12px;
                    }
                    p {
                        margin-bottom: 0;
                    }
                    a {
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: $pink-color;
                        display: inline-block;

                        &:hover {
                            color: $blue-color;
                        }
                    }
                }
            }
        }
        .details-form {
            background-color: #f4f4f4;
            border-radius: 5px;
            padding: 28px 25px 35px;

            h3 {
                color: #1c2522;
                margin-bottom: 10px;
                font-size: 20px;
            }
            .form-group {
                margin-bottom: 30px;
                position: relative;

                i {
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    font-size: 21px;
                }
                .form-control {
                    height: 50px;
                    border-radius: 5px;
                    padding-left: 40px;
                    border: 1px solid #e0e0e0;
                    font-size: 14px;
                    transition: $transition;

                    &:focus {
                        border: 1px solid $pink-color;
                        box-shadow: none;
                    }
                }
                textarea {
                    height: auto !important;
                    padding-top: 13px;
                }
            }
            .btn {
                color: $white-color;
                background-color: $pink-color;
                transition: $transition;
                padding: 12px 40px;
                border-radius: 5px;
                font-weight: 500;

                &:hover {
                    background-color: $blue-color;
                }
            }
        }
    }
}
/*----- End Blog Details CSS -----*/    

/*----- Contact CSS -----*/    
.contact-info-area {
    .info-item {
        margin-bottom: 30px;
        text-align: center;
        border: 1px dashed $pink-color;
        border-radius: 5px;
        padding: 30px 25px 30px;
        transition: $transition;

        &:hover {
            transform: translate(0, -10px);

            i {
                transform: rotate(360deg);
            }
        }
        i {
            font-size: 65px;
            color: $pink-color;
            margin-bottom: 10px;
            transition: $transition;
        }
        h3 {
            font-size: 20px;
            color: $blue-color;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 15px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    color: $pink-color;
                    margin-right: 3px;
                    display: inline-block;
                }
                a {
                    color: $grey-color;
                    display: inline-block;

                    &:hover {
                        color: $pink-color;
                    }
                }
            }
        }
    }
}
.contact-form-area {
    .form-item {
        text-align: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fafafa;
        padding: 30px 30px 30px;
        border-radius: 5px;
        position: relative;

        h2 {
            font-size: 30px;
            margin-bottom: 35px;
            color: $blue-color;
            position: relative;
            padding-bottom: 14px;

            &:before {
                position: absolute;
                content: '';
                width: 50px;
                height: 3px;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $blue-color;
                margin-left: auto;
                margin-right: auto;
            }
            &:after {
                position: absolute;
                content: '';
                width: 40px;
                height: 3px;
                left: 0;
                right: 0;
                bottom: -6px;
                background-color: $blue-color;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .form-group {
            margin-bottom: 30px;
            text-align: left;

            label {
                margin-bottom: 10px;
                font-weight: 500;
                color: $blue-color;
            }
            .form-control {
                height: 50px;
                border-radius: 5px;
                border: 1px solid $white-color;
                transition: $transition;
                padding-left: 20px;
                font-size: 14px;

                &:focus {
                    box-shadow: none;
                    border: 1px solid $blue-color;
                }
            }
            textarea {
                height: auto !important;
            }
            .list-unstyled {
                margin-top: 10px;
                font-size: 14px;
                color: #dc3545;
            }
        }
        .btn {
            color: $white-color;
            background-color: $pink-color;
            padding: 12px 30px;
            border-radius: 5px;
            border: 0;
            transition: $transition;
            opacity: 1;

            &:hover {
                background-color: $blue-color;
            }
        }
        .text-danger {
            margin-bottom: 0;
            margin-top: 10px;
            font-size: 25px;
            color: #dc3545;
        }
        .text-success {
            margin-bottom: 0;
            margin-top: 10px;
            font-size: 25px;
            color: #28a745;
        }
    }
}
.map-area {
    #map {
        height: 400px;
        width: 100%;
        border: 0;
        display: block;
    }
}
/*----- End Contact CSS -----*/    

/*----- User Form CSS -----*/   
.user-form-area {
    height: 100vh;

    .user-img {
        background-image: url('assets/img/resgister-bg.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 800px;

        img {
            display: none;
        }
    }
    .user-content {
        padding-left: 30px;
        max-width: 600px;
        margin-left: 0;
        margin-right: auto;
        text-align: center;
        padding-right: 30px;

        .top {
            margin-bottom: 30px;

            h2 {
                font-size: 35px;
                color: $pink-color;
                font-weight: 600;
                font-family: $common-font;
                position: relative;
                padding-bottom: 10px;
                margin-bottom: 40px;

                &:before {
                    position: absolute;
                    content: '';
                    width: 50px;
                    height: 3px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $pink-color;
                }
                &:after {
                    position: absolute;
                    content: '';
                    width: 40px;
                    height: 3px;
                    left: 0;
                    right: 0;
                    bottom: -8px;
                    margin-left: auto;
                    margin-right: auto;
                    background-color: $pink-color;
                }
            }
            .form-group {
                margin-bottom: 15px;
                text-align: left;

                .form-control {
                    height: 50px;
                    padding-left: 20px;
                    font-size: 14px;
                    border-radius: 5px;
                    transition: $transition;
                    background-color: #f5f5f5;
                    border: 1px solid #f5f5f5;

                    &:focus {
                        border: 1px solid $pink-color;
                        box-shadow: none;
                    }
                }
            }
            .btn {
                color: $white-color;
                background-color: $pink-color;
                border-radius: 5px;
                border: 0;
                transition: $transition;
                padding: 13px 35px;
                width: 100%;
                display: block;
                font-weight: 500;

                &:hover {
                    background-color: $blue-color;
                }
            }
        }
        .end {
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-left: 3px;
                    margin-right: 3px;

                    a {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        text-align: center;
                        display: inline-block;
                        color: $white-color;
                        background-color: #3b5998;
                        font-size: 20px;

                        i {
                            line-height: 40px;
                        }
                        &:hover {
                            transform: rotateY(360deg);
                        }
                    }
                    &:nth-child(2) {
                        a {
                            background-color: #55acee;
                        }
                    }
                    &:nth-child(3) {
                        a {
                            background-color: #3f729b;
                        }
                    }
                    &:nth-child(4) {
                        a {
                            background-color: #ff0000;
                        }
                    }
                }
            }
        }
    }
} 
/*----- End User Form CSS -----*/    

/*----- Employers CSS -----*/    
.employer-area.two {
    padding-top: 0;

    .employer-item {
        padding: 25px 30px 25px 95px;

        ul {
            li {
                &:nth-child(2) {
                    margin-right: 0;

                    i {
                        top: 0;
                        margin-right: 0;
                    }
                }
                &:nth-child(3) {
                    margin-right: 0;

                    i {
                        top: 0;
                        margin-right: 0;
                    }
                }
                &:nth-child(4) {
                    margin-right: 0;

                    i {
                        top: 0;
                        margin-right: 0;
                    }
                }
                &:nth-child(5) {
                    margin-right: 0;

                    i {
                        top: 0;
                        margin-right: 0;
                    }
                }
                &:nth-child(6) {
                    margin-right: 0;

                    i {
                        top: 0;
                        margin-right: 0;
                    }
                }
                &:nth-child(7) {
                    span {
                        display: inline-block;
                        color: $grey-color;
                        margin-left: 8px;
                    }
                }
                .checked {
                    color: #ff6c00;
                }
            }
        }
    }
}
/*----- End Employers CSS -----*/    

/*----- Employer Details CSS -----*/   
.employer-details-area {
    .client-wrap {
        padding-top: 20px;

        h2 {
            font-size: 20px;
            margin-bottom: 20px;
            color: $blue-color;
        }
    }
    .client {
        border: 1px dashed $pink-color;
        border-radius: 5px;
        background-color: #f8e8e9;
        padding: 20px 20px 20px 155px;
        position: relative;
        margin-bottom: 30px;

        img {
            position: absolute;
            top: 20px;
            left: 20px;
            border-radius: 50%;
        }
        h3 {
            color: #1b2b37;
            font-size: 20px;
            margin-bottom: 5px;
        }
        span {
            display: block;
            font-size: 14px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
        }
        ul {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 20px;
            right: 20px;

            li {
                list-style-type: none;
                display: inline-block;

                span {
                    color: $white-color;
                    background-color: $pink-color;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    margin-bottom: 0;
                    margin-right: 5px;
                }
                i {
                    display: inline-block;
                    color: #ddd;
                }
                .checked {
                    color: #ed4800;
                }
            }
        }
    }
    .review {
        background-color: #f4f4f4;
        border-radius: 5px;
        padding: 28px 25px 35px;

        h3 {
            color: #1c2522;
            margin-bottom: 5px;
            font-size: 20px;
        }
        span {
            display: block;
            margin-bottom: 8px;
        }
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 20px;

            li {
                list-style-type: none;
                display: inline-block;

                span {
                    color: $white-color;
                    background-color: $pink-color;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    margin-bottom: 0;
                    margin-right: 5px;
                }
                i {
                    display: inline-block;
                    color: #ddd;
                }
                .checked {
                    color: #ed4800;
                }
            }
        }
        .form-group {
            margin-bottom: 30px;
            position: relative;

            i {
                position: absolute;
                left: 15px;
                top: 15px;
                font-size: 21px;
            }
            .form-control {
                height: 50px;
                border-radius: 5px;
                padding-left: 40px;
                border: 1px solid #e0e0e0;
                font-size: 14px;
                transition: $transition;

                &:focus {
                    border: 1px solid $pink-color;
                    box-shadow: none;
                }
            }
            textarea {
                height: auto !important;
                padding-top: 13px;
            }
        }
        .btn {
            color: $white-color;
            background-color: $pink-color;
            transition: $transition;
            padding: 12px 40px;
            border-radius: 5px;
            font-weight: 500;

            &:hover {
                background-color: $blue-color;
            }
        }
    }
} 
/*----- End Employer Details CSS -----*/    

/*----- Testimonials CSS -----*/    
.feedback-area.two {
    background-color: $white-color;
    padding-bottom: 70px;

    &:before {
        display: none;
    }
    .feedback-item {
        margin-bottom: 45px;
        box-shadow: 0px 0px 20px 0px #dddddd57;
    }
}
/*----- End Testimonials CSS -----*/    

/*----- FAQ CSS -----*/    
.faq-area {
    .faq-content {
        .accordion { 
            margin: 0;
            padding: 0;
        
            p {
                display: none;
                padding: 20px 15px 15px 15px;
                margin-bottom: 0;
            }
            a {
                width: 100%;
                display: block;
                cursor: pointer;
                padding: 15px 15px 15px 18px;
                border: 1px solid $blue-color;
                font-weight: 500;
                border-radius: 5px;
                font-size: 17px;
                color: $blue-color;
    
                &:after {
                    position: absolute;
                    right: 20px;
                    content: "+";
                    top: 10px;
                    color: #000000;
                    font-size: 25px;
                    font-weight: 500;
                }
                &.active {
                    color: $white-color;
                    background-color: $blue-color;
                    border: 1px solid $blue-color;
                }
                &.active:after {
                    content: "-";
                    font-size: 25px;
                    color: $white-color;
                }
            }
            li {
                position: relative;
                list-style-type: none;
                margin-bottom: 30px;
                display: block;
        
                &:first-child {
                    border-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .faq-img {
        background-image: url('assets/img/faq-main.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        border-radius: 5px;

        img {
            display: none;
        }
    }
    .faq-bottom {
        margin-top: 30px;
        text-align: center;

        h3 {
            color: $blue-color;
            font-size: 30px;
            margin-bottom: 15px;
            max-width: 485px;
            margin-left: auto;
            margin-right: auto;
        }
        a {
            display: inline-block;
            color: $white-color;
            background-color: $pink-color;
            border-radius: 5px;
            padding: 12px 25px;
            font-weight: 500;

            &:hover {
                background-color: $blue-color;
            }
        }
    }
}
/*----- End FAQ CSS -----*/    

/*----- Coming Soon CSS -----*/
.coming-soon-area {
    height: 100vh;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .soon-item {
        h1 {
            font-weight: 900;
            font-size: 100px;
            margin-bottom: 15px;
            color: $pink-color;
        }
        span {
            display: inline-block;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 30px;
            color: $blue-color;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 35px;
                height: 2px;
                left: -45px;
                top: 14px;
                background-color: $blue-color;
            }
            &:after {
                position: absolute;
                content: '';
                width: 35px;
                height: 2px;
                right: -45px;
                top: 14px;
                background-color: $blue-color;
            }
        }
        .coming-inner {
            padding-top: 20px;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-radius: 5px;
            background-color: $blue-color;

            h3 {
                font-weight: 700;
                font-size: 40px;
                margin-bottom: 0;
                color: $white-color;
            }
            p {
                margin-bottom: 0;
                color: $white-color;
            }
        }
    }
}
/*----- End Coming Soon CSS -----*/

/*----- Error CSS -----*/
.error-area {
    height: 100vh;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .error-content {
        h1 {
            font-size: 130px;
            color: $pink-color;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 25px;
            margin-bottom: 10px;
            color: $blue-color;
        }
        p {
            font-weight: 500;
            margin-bottom: 20px;
        }
        a {
            display: inline-block;
            color: $white-color;
            background-color: $pink-color;
            padding: 12px 35px;
            border-radius: 5px;
            font-weight: 500;

            &:hover {
                background-color: $blue-color;
            }
        }
    }
}
/*----- End Error CSS -----*/

/*----- Rules CSS -----*/
.rules-area {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .rules-item {
        h3 {
            font-size: 20px;
            margin-bottom: 10px;
            color: $blue-color;
        }
        p {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/*----- End Rules CSS -----*/

/*----- Dashboard CSS -----*/
.dashboard-area {
    .profile-item {
        text-align: center;

        img {
            display: block;
            border-radius: 50%;
            border: 5px solid $white-color;
            box-shadow: 0px 0px 20px 0px #dddddd24;
            margin-bottom: 15px;
            margin-left: auto;
            margin-right: auto;
        }
        h2 {
            font-size: 25px;
            color: $blue-color;
            margin-bottom: 5px;
        }
        span {
            display: block;
            margin-bottom: 30px;
        }
    }
    .nav-pills {
        a {
            color: $blue-color;
            font-size: 17px;
            margin-bottom: 10px;
            border-bottom: 1px solid #e9e9e9;
            border-radius: 5px;
            display: inline-block;
            margin-bottom: 0;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 10px;
            padding-right: 10px;

            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 3px;
                margin-right: 5px;
                color: $pink-color;
            }
        }
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: $blue-color;
    }
    .profile-content {
        text-align: center;

        .profile-content-inner {
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 35px 40px 10px;
            margin-bottom: 30px;
        }
        h2 {
            color: $blue-color;
            margin-bottom: 40px;
            font-size: 35px;
            padding-bottom: 10px;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 50px;
                height: 3px;
                left: 0;
                right: 0;
                bottom: 0;
                margin-left: auto;
                margin-right: auto;
                background-color: $pink-color;
            }
            &:after {
                position: absolute;
                content: '';
                width: 40px;
                height: 3px;
                left: 0;
                right: 0;
                bottom: -6px;
                margin-left: auto;
                margin-right: auto;
                background-color: $pink-color;
            }
        }
        .form-group {
            text-align: left;
            margin-bottom: 30px;

            label {
                display: inline-block;
                font-weight: 500;
                color: $blue-color;
                margin-bottom: 8px;
            }
            .form-control {
                height: 50px;
                border: 0;
                border-radius: 5px;
                padding-left: 20px;
                font-size: 14px;
                border: 1px solid $white-color;
                transform: $transition;

                &:focus {
                    box-shadow: none;
                    border: 1px solid $pink-color;
                }
            }
        }
        .dashboard-btn {
            width: 100%;
            padding-top: 13px;
            padding-bottom: 13px;
            border: 0;
            border-radius: 5px;
            color: $white-color;
            background-color: $pink-color;
            transition: $transition;
            font-weight: 500;

            &:hover {
                background-color: $blue-color;
            }
        }
    }
    .employer-item {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
/*----- End Dashboard CSS -----*/

/*----- Resume CSS -----*/
.resume-area {
    .details-item {
        .skills {
            margin-bottom: 0;
        }
    }
    .resume-profile {
        text-align: center;
        margin-bottom: 30px;

        img {
            display: block;
            border-radius: 50%;
            border: 5px solid $white-color;
            box-shadow: 0px 0px 20px 0px #dddddd24;
            margin-bottom: 15px;
            margin-left: auto;
            margin-right: auto;
        }
        h2 {
            font-size: 25px;
            color: $blue-color;
            margin-bottom: 5px;
        }
        span {
            display: block;
            margin-bottom: 30px;
        }
    }
}
/*----- End Resume CSS -----*/

/*----- Preloader -----*/
.loader {
    position: fixed;
    z-index: 99999;
    background: $pink-color;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
.spinner {
    width: 50px;
    height: 50px;
    background-color: $white-color;
    margin: 100px auto;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}
@keyframes sk-rotateplane {
    0% { 
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    } 
    50% { 
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    } 
    100% { 
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
/*----- End Preloader -----*/

/*----- Back to Top -----*/
#toTop {
	position: fixed;
	bottom: 30px;
    right: 30px;
	cursor: pointer;
    display: none;
    z-index: 10;
}
.back-to-top-btn {
    i {
        background: $pink-color;
        color: $white-color;
        height: 50px;
        width: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 25px;
        border-radius: 50% 50% 0 0;
        transition: $transition;
        animation: back-to-top-ani 2s infinite linear;

        &:hover {
            background-color: $blue-color;
        }
    }
}
@keyframes back-to-top-ani {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -6px);
    }
    100% {
        transform: translate(0, 0);
    }
}
/*----- End Back to Top -----*/